import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import swal from "sweetalert";
import {UserContext} from '../Auth/Context/Context'
import {baseURL, DELETE_USER, USERS_LIST} from "../Apis/Api";
import Cookies from "universal-cookie";

export default function UsersList ()
{

    const [userList, setUsersList] = useState([{}]);
    const [deletedUser , setDeletedUser] = useState(false);

    const cookies = new Cookies();
    const token = cookies.get('Bearer');

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${USERS_LIST}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setUsersList(res.data)
            });
        };
        getData();
    }, [deletedUser]);

    async function deleteUser(id)
    {
        await axios.get(`${baseURL}/${DELETE_USER}/${id}`,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }}).then((res) => {
            swal("Success",res.data.message,"success");
            setDeletedUser(!deletedUser);
        });
    }

    return (
        <main id="homeMain" tabIndex="-1">
            <div className="container-fluid">
                <div className="containerWrap">
                    <div className="row bodyHeader">
                        <div className="col-auto">
                            <h1>Users Table</h1>
                            <ul className="breadcrumb">
                                <li><Link to="/dashboard">Dashboard</Link></li>
                                <li><Link to="">Users table</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mainBody">
                            <table className="table table-striped dataTable no-footer" id="samatek-datatable" aria-describedby="samatek-datatable_info"
                                   data-table-max-column="6">
                                <thead>
                                <tr>
                                    <th>id</th>
                                    <th>UserName</th>
                                    <th>Email</th>
                                    <th>action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userList.map((user,index)=>
                                        <tr key={index}>
                                            <td>{user.id}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.status}</td>
                                            <td>
                                <span className="function">
                                <Link className="alert-success" title="edit"  to={`/edit-user/${user.id}`}>
                                <i className="bi bi-pencil-square"></i>
                                </Link>
                                <button onClick={()=>deleteUser(user.id)} className="alert-danger" type="submit" title="delete"><i className="bi bi-trash3"></i></button>
                                </span>
                                            </td>
                                        </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <div className="functionBar">
                            <div className="col-auto fanctions">
                                <Link to="/add-user" className="fancItem alert-success" title="add">
                                    <i className="bi bi-node-plus"></i><span>New User</span>
                                </Link>
                            </div>
                            <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
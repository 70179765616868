import {Link, useNavigate} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {ADD_PAGE, baseURL, CURRENT_LOCALE, GET_PAGES} from "../Apis/Api";
import {Editor} from "@tinymce/tinymce-react";
export default function AddPage()
{

    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [pageImage , setPageImage] = useState([]);
    const [pageBanner, setPageBanner] = useState([]);
    const [mobileBanner, setMobileBanner] = useState([]);
    const [selectedPage, setSelectedPage] = useState(0);
    const [pages, setPages] = useState([]);
    const [locale , setLocale] = useState('');
    const [mceEditor , setMceEditor] =  useState(null);
    const navigate = useNavigate();

    const [allCheckBox , setCheckBoxes] = useState({
        no_index:false,
        featured:false,
        hide_from_nav:false,
        in_footer:false,
        status:false,
    });
    const [pageData , setPageData] = useState({
        parent_id:"",
        title:"",
        heading_title:"",
        page_content:"",
        page_url:"",
        meta_title:"",
        meta_description:""
    });

    const pageNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const fillUrl =  () => {

        let name = pageNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="" &&  locale!=="0") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setPageData({...pageData, ['page_url']: NSlocale + '/' + NSname});

            pageData.product_url = NSlocale + '/' + NSname;;
        }else{
            metaUrl.current.value = "";
            setPageData({...pageData, ['page_url']: ""});
        }
    }

    const fillMetaTitle = ()=> {
        let name = pageNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="")
        {
            let siteName = 'androas';
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            pageData.meta_title = NSname + ' | ' + siteName;
        }else{
            metaTitle.current.value = "";
            pageData.meta_title = "";
        }
    }

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${CURRENT_LOCALE}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setLocale(res.data.locale);
            });

            await axios.get(`${baseURL}/${GET_PAGES}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setPages(res.data[0]);
            });
        };
        getData();
    }, []);


    async function handleCheckBox (e) {
        e.persist();
        setCheckBoxes({
            ...allCheckBox , [e.target.name]:e.target.checked
        });
    };

    const handleInput = (e) => {
        e.persist();
        setPageData({...pageData, [e.target.name]:e.target.value });

    }

    const addPage = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(pageData));

        if(pageData.title==="" || pageData.meta_title==="" || pageData.page_url==="") {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('parent_id', selectedPage || 0);
            formData.append('title', pageData.title || "");
            formData.append('heading_title', pageData.heading_title || "");
            formData.append('meta_title', pageData.meta_title || "");
            formData.append('meta_description', pageData.meta_description || "");
            formData.append('page_url', pageData.page_url || "");
            formData.append('page_content', mceEditor || "");
            formData.append('page_banner',pageBanner[0]);
            formData.append('mobile_banner',mobileBanner[0]);
            formData.append('page_image',pageImage[0]);
            formData.append('no_index', allCheckBox.no_index ? '1':'0');
            formData.append('in_footer', allCheckBox.in_footer ? '1':'0');
            formData.append('hide_from_nav', allCheckBox.hide_from_nav ? '1':'0');
            formData.append('status', allCheckBox.status ? '1':'0');

            axios.post(`${baseURL}/${ADD_PAGE}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                if(res.data.status === 200)
                {
                    swal("Success",res.data.message,"success");
                    navigate('/pages-list' , {replace:true});
                }else{
                    swal("Warning",res.data.error,"warning");

                }
            });
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addPage}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>New page</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/pages-list">Pages table</Link></li>
                                        <li><Link to="#">New page</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Page Information</h2>
                                    <input hidden  readOnly   ref={localeRef} value={locale} onChange={()=>console.log('')}/>

                                    <div className="formGroup required">
                                        <label htmlFor="parent_id">Select page</label>
                                        <select
                                            className="form-control"
                                            data-live-search="true" name="parent_id" id="parent_id"
                                            onChange={(e)=>setSelectedPage(e.target.value)}>
                                            <option value="0">select page</option>
                                            {pages.map((page,index)=>
                                                <option key={index} value={page.id}>{page.title}</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="title" className="form-label">Page title</label>
                                        <input type="text"
                                               ref={pageNameRef}
                                               id="title"
                                               name="title"
                                               className="form-control"
                                               value={pageData.title}
                                               onChange={handleInput}
                                        />
                                        {errors.title && <em className="error hint" style={{color:'red'}}>{errors.title}</em>}

                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="heading_title" className="form-label">Heading title</label>
                                        <input type="text"
                                               id="heading_title"
                                               name="heading_title"
                                               className="form-control"
                                               value={pageData.heading_title}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup quarter required">
                                        <label htmlFor="page_url"  className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="page_url"
                                            id="page_url"
                                            value={pageData.page_url}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.page_url && <em className="error hint" style={{color:'red'}}>{errors.page_url}</em>}
                                    </div>


                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={pageData.meta_title}
                                            onChange={handleInput}
                                        />
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                    </div>

                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={pageData.meta_description}
                                            onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="page_image" className="form-label">Page image 500 X 500</label>
                                        <input type="file" name="page_image"
                                               onChange={(e) =>setPageImage(e.target.files)}
                                               className="form-control"/>
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="page_banner" className="form-label">Page banner 1900 X 500</label>
                                        <input type="file" name="page_banner"
                                               onChange={(e) =>setPageBanner(e.target.files)}
                                               className="form-control"/>
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="mobile_banner" className="form-label">Mobile banner 767 X 300</label>
                                        <input type="file" name="mobile_banner"
                                               onChange={(e) =>setMobileBanner(e.target.files)}
                                               className="form-control"/>
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="page_content" className="form-label">Page Content</label>
                                        <Editor
                                        apiKey='kds61advbpqoiaoaf8d490hcgwr9ula3jelsk1l0xwgq4ixh'
                                            id="page_content"
                                            value={mceEditor}
                                            onEditorChange={setMceEditor}
                                        />
                                    </div>


                                    <div className="formGroup wide checkGroup">
                                <span>
                                    <input  className="form-check-input" name="status"
                                            checked={allCheckBox.status}
                                            onChange={handleCheckBox}
                                            id="status" type="checkbox"/>
                                    <label htmlFor="status" className="form-check-label">Status</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="featured"
                                            checked={allCheckBox.featured}
                                            onChange={handleCheckBox}
                                            id="featured" type="checkbox"/>
                                    <label htmlFor="featured" className="form-check-label">Featured</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="in_footer"
                                            checked={allCheckBox.in_footer}
                                            onChange={handleCheckBox}
                                            id="in_footer" type="checkbox"/>
                                    <label htmlFor="in_footer" className="form-check-label">In footer</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="no_index"
                                            checked={allCheckBox.no_index}
                                            onChange={handleCheckBox}
                                            id="no_index" type="checkbox"/>
                                    <label htmlFor="no_index" className="form-check-label">No index</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="hide_from_nav"
                                            checked={allCheckBox.hide_from_nav}
                                            onChange={handleCheckBox}
                                            id="hide_from_nav" type="checkbox"/>
                                    <label htmlFor="hide_from_nav" className="form-check-label">Hide from navigation</label>
                                </span>


                                    </div>


                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                                <div className="functionBar">
                                    <div className="col-auto fanctions">
                                        <Link to="" className="fancItem alert-success">
                                            <i className="bi bi-card-list"></i><span>customers</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-warning">
                                            <i className="bi bi-node-plus"></i><span>add_supplier</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-primary">
                                            <i className="bi bi-node-plus"></i><span>create_account</span>
                                        </Link>
                                    </div>
                                    <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
export default function Footer()
{
    return (
        <footer className="footer">
            <div className="container">
                <div className="row justify-content-center">
                    {/*@if(App::isLocale('en'))*/}
                    {/*<div className="col-auto">All Rights Reserved &copy; Samatek Web Solutions*/}
                    {/*    <?php echo \Carbon\Carbon::now()->format('Y'); ?>*/}
                    {/*</div>*/}
                    {/*@else*/}
                    <div className="col-auto">كافة الحقوق محفوظة &copy; سماتك لحلول الانترنت والبرمجيات
                        </div>
                    {/*@endif*/}
                </div>
            </div>
        </footer>

    )
}
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import swal from "sweetalert";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {
    baseURL,
    BRAND_SELECT_LIST, CATEGORIES_TABLE,
    DELETE_PRODUCT,
    PRODUCTS,
} from "../Apis/Api";

export default function ProductsList ()
{

    const [productsList, setProductsList] = useState([]);
    const [locales , setLocales] = useState([]);
    const [categoriesList , setCategoriesList] = useState([]);
    const [brandList , setBrandList] = useState([]);
    const [deletedProduct , setDeletedProduct] = useState(false);
    const cookies = new Cookies();
    const token = cookies.get('Bearer');

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${PRODUCTS}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setProductsList(res.data[0])
                setLocales(res.data[1])
            });

            await axios.get(`${baseURL}/${CATEGORIES_TABLE}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setCategoriesList(res.data[0])
            });

            await axios.get(`${baseURL}/${BRAND_SELECT_LIST}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setBrandList(res.data[0])
            });


        };
        getData();
    }, [deletedProduct]);

    async function deleteProduct(id)
    {
        await axios.get(`${baseURL}/${DELETE_PRODUCT}/${id}`,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            swal("Success",res.data.message,"success");
            setDeletedProduct(!deletedProduct);
        });
    }

    return (
       <div>
           <Header/>
        <main id="homeMain" tabIndex="-1">
            <div className="container-fluid">
                <div className="containerWrap">
                    <div className="row bodyHeader">
                        <div className="col-auto">
                            <h1>Products Table</h1>
                            <ul className="breadcrumb">
                                <li><Link to="/dashboard">Dashboard</Link></li>
                                <li><Link to="">Products table</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mainBody">
                            <table className="table table-striped dataTable no-footer" id="samatek-datatable" aria-describedby="samatek-datatable_info"
                                   data-table-max-column="6">
                                <thead>
                                <tr>
                                    <th>id</th>
                                    <th>product name</th>
                                    <th>Category</th>
                                    <th>Brand</th>
                                    <th>meta title</th>
                                    <th>Languages</th>
                                    <th>status</th>
                                    <th>action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {productsList.map((product,index)=>
                                        <tr key={index}>
                                            <td>{product.id}</td>
                                            <td>{product.product_name}</td>
                                            <td>{categoriesList.map((category,index)=>
                                                product.category_id === category.id ? category.category_name : ''
                                           ) }</td>
                                            <td>{brandList.map((brand , index)=>
                                            product.brand_id === brand.id ? brand.brand_name : ''
                                            )}</td>
                                            <td>{product.meta_title}</td>
                                            <td>
                                                {product.translations.map((translation,index)=>
                                                    <Link to={`/edit-product-translation/${translation.id}/${product.id}`} key={index}>{translation.locale}</Link>
                                                )}
                                            </td>
                                            <td>{product.status}</td>
                                            <td>
                                      <span className="function">
                                       <Link className="alert-success" title="edit"  to={`/edit-product/${product.id}`}>
                                       <i className="bi bi-pencil-square"></i>
                                        </Link>
                                 <Link className="alert-success" title="add"  to={`/add-product-translation/${product.id}`}>
                                <i className="bi bi-node-plus"></i>
                                </Link>
                                <button onClick={()=>deleteProduct(product.id)} className="alert-danger" type="submit" title="delete"><i className="bi bi-trash3"></i></button>
                                </span>
                                </td>
                            </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <div className="functionBar">
                            <div className="col-auto fanctions">
                                <Link to="/add-product" className="fancItem alert-success" title="add">
                                    <i className="bi bi-node-plus"></i><span>New product</span>
                                </Link>
                            </div>
                            <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
           <Footer/>
       </div>
    )
}
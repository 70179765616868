import Header from "./Header";
import Footer from "./Footer";
import {UserContext} from "../Auth/Context/Context";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {baseURL, USER} from "../Apis/Api";
import Cookies from "universal-cookie";

export default function Content ()
{
    const [name , setName] = useState('');
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    useEffect(()=>{
        axios.get(`${baseURL}/${USER}`,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((data)=>{
            setName(data.data.name);
        })
    },[])
    return (
     <div>
         <Header/>
         <main id="homeMain" tabIndex="-1">
             <div className="container-fluid">
                 <div className="containerWrap">
                     <div className="row dashBoardHeader">
                         <div className="col-auto">
                             <h1>Welcome back {name}</h1>
                         </div>
                     </div>
                     <div className="row dashBoardWrap">
                         <div className="item">
                             <h2 className="dashBoard">جدول معلومات</h2>
                             <table className="tableStyle" id="main-table">
                                 <tbody>
                                 <tr>
                                     <th>Company</th>
                                     <th>Contact</th>
                                     <th>Country</th>
                                 </tr>
                                 <tr>
                                     <td>Alfreds Futterkiste</td>
                                     <td>Maria Anders</td>
                                     <td>Germany</td>
                                 </tr>
                                 <tr>
                                     <td>Centro comercial Moctezuma</td>
                                     <td>Francisco Chang</td>
                                     <td>Mexico</td>
                                 </tr>
                                 <tr>
                                     <td>Centro comercial Moctezuma</td>
                                     <td>Francisco Chang</td>
                                     <td>Mexico</td>
                                 </tr>
                                 <tr>
                                     <td>Centro comercial Moctezuma</td>
                                     <td>Francisco Chang</td>
                                     <td>Mexico</td>
                                 </tr>
                                 <tr>
                                     <td>Centro comercial Moctezuma</td>
                                     <td>Francisco Chang</td>
                                     <td>Mexico</td>
                                 </tr>
                                 <tr>
                                     <td>Centro comercial Moctezuma</td>
                                     <td>Francisco Chang</td>
                                     <td>Mexico</td>
                                 </tr>
                                 <tr>
                                     <td>Centro comercial Moctezuma</td>
                                     <td>Francisco Chang</td>
                                     <td>Mexico</td>
                                 </tr>

                                 </tbody>
                             </table>
                         </div>
                         <div className="item wide">
                             <div className="mini-rectangels-container">
                                 <a id="data-rectangle" href="http://acc.sama-tek.com/users">
                                     <span className="shortcuts-icon"><i className="bi bi-people-fill"></i></span>
                                     <span id="data-rec-word">الحسابات</span>
                                     <span id="data-rec-number">4</span>
                                 </a>
                                 <a id="data-rectangle" className="notification"
                                    href="http://acc.sama-tek.com/notification">
                                     <span className="shortcuts-icon"><i className="bi bi-bell-fill"></i></span>
                                     <span id="data-rec-word">التنبيهات</span>
                                     <span id="data-rec-number">0</span>
                                 </a>

                                 <a id="data-rectangle" href="http://acc.sama-tek.com/customers">
                                     <span className="shortcuts-icon"><i className="bi bi-people-fill"></i></span>
                                     <span id="data-rec-word">الزبائن</span>
                                     <span id="data-rec-number">6</span>
                                 </a>

                                 <a id="data-rectangle" href="http://acc.sama-tek.com/products">
                                     <span className="shortcuts-icon"><i className="bi bi-box-seam-fill"></i></span>
                                     <span id="data-rec-word">المنتجات</span>
                                     <span id="data-rec-number">5</span>
                                 </a>
                                 <a id="data-rectangle" href="http://acc.sama-tek.com/categories">
                                     <span className="shortcuts-icon"><i className="bi bi-journal-text"></i></span>
                                     <span id="data-rec-word">التصنيفات</span>
                                     <span id="data-rec-number">5</span>
                                 </a>
                                 <a id="data-rectangle" href="http://acc.sama-tek.com/currencies">
                                     <span className="shortcuts-icon"><i className="bi bi-currency-exchange"></i></span>
                                     <span id="data-rec-word">سعر الصرف</span>
                                     <span id="data-rec-number">3600</span>
                                 </a>
                                 <a id="data-rectangle" href="http://acc.sama-tek.com/stocks">
                                     <span className="shortcuts-icon"><i className="bi bi-shop"></i></span>
                                     <span id="data-rec-word">المخزون</span>
                                     <span id="data-rec-number">5</span>
                                 </a>
                                 <a id="data-rectangle" href="#">
                                     <span className="shortcuts-icon"><i className="bi bi-tags-fill"></i></span>
                                     <span id="data-rec-word">عروض اسعار</span>
                                     <span id="data-rec-number">4</span>
                                 </a>
                             </div>
                         </div>
                         <div className="item">
                             <h2 className="dashBoard">ملاحظات ومواعيد</h2>
                             <ul className="notification">
                                 <li className="item">
                                     <a>
                                         <span className="notification-heading"><em>Mon 10:00</em> عنوان الملاحظة</span>
                                         <span className="notification-text">Hi Teddy, Just wanted to let you ...</span>
                                     </a>
                                 </li>
                                 <li className="item">
                                     <a>
                                         <span className="notification-heading"><em>Mon 10:00</em> عنوان الملاحظة</span>
                                         <span className="notification-text">Hi Teddy, Just wanted to let you ...</span>
                                     </a>
                                 </li>
                                 <li className="item">
                                     <a>
                                         <span className="notification-heading"><em>Mon 10:00</em> عنوان الملاحظة</span>
                                         <span className="notification-text">Hi Teddy, Just wanted to let you ...</span>
                                     </a>
                                 </li>
                                 <li className="item">
                                     <a>
                                         <span className="notification-heading"><em>Mon 10:00</em> عنوان الملاحظة</span>
                                         <span className="notification-text">Hi Teddy, Just wanted to let you ...</span>
                                     </a>
                                 </li>
                             </ul>
                         </div>
                     </div>

                 </div>
             </div>
         </main>
         <Footer/>
     </div>

    )
}
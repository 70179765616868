import {Link, useNavigate, useParams} from 'react-router-dom'
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Validation from "../Validation/Validation";
import swal from "sweetalert";
import {
    baseURL,
    DELETE_LOGO_IMAGE,
    DELETE_PAGE_BANNER_IMAGE, EDIT_PROFILE_TRANSLATION,
    imageUrl,
    PROFILE_TRANSLATION_DATA,
    UNUSED_PROFILE_TRANSLATION
} from "../Apis/Api";

export default function EditProfileTranslation ()
{
    const {id , profileId} = useParams();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const navigate = useNavigate();
    const [profileData , setProfileData] = useState({
        title:'',
        site_name:'',
        email:'',
        address:'',
        first_phone:'',
        second_phone:'',
        whats_app:'',
        facebook:'',
        youtube:'',
        instagram:'',
        locale:'',
        logo:""
    });

    const logoImageRef = useRef();
    const currentLogoImage = useRef(null);

    const [logo , setLogo] = useState([]);

    const handleInput = (e) => {
        e.persist();
        setProfileData({...profileData, [e.target.name]:e.target.value });

    }


    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${UNUSED_PROFILE_TRANSLATION}/${id}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {

            });

            await axios.get(`${baseURL}/${PROFILE_TRANSLATION_DATA}/${id}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setProfileData(res.data.profileData)
            });
        };
        getData();
    }, []);


    const deleteLogoImage=() => {
        axios.post(`${baseURL}/${DELETE_LOGO_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            swal("Success",res.data.message,"success");

        })
    }

    const addProfileTranslation = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(profileData));

        if(profileData.site_name ==="") {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {   e.preventDefault();
            const formData = new FormData();
            formData.append('profile_id', profileId);
            formData.append('site_name', profileData.site_name || "");
            formData.append('email', profileData.email || "");
            formData.append('address', profileData.address || "");
            formData.append('first_phone', profileData.first_phone || "");
            formData.append('second_phone', profileData.second_phone || "");
            formData.append('whats_app', profileData.whats_app || "");
            formData.append('facebook', profileData.facebook || "");
            formData.append('youtube', profileData.youtube || "");
            formData.append('instagram', profileData.instagram || "");
            formData.append('language', 'language');
            formData.append('locale', profileData.locale);
            formData.append('logo', logo[0] || "");
            formData.append('current_logo', currentLogoImage.current.value);


            axios.post(`${baseURL}/${EDIT_PROFILE_TRANSLATION}/${id}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                    if(res.data.status === 200) {
                        swal("Success",res.data.message,"success");
                        navigate('/profile');

                    }else
                    {
                        swal("Warning",res.data.error,"warning");

                    }

                }
            );
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addProfileTranslation}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Profile</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/profile">Profile</Link></li>
                                        <li><Link to="#">Edit translation</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Edit Translation</h2>

                                    <div className="formGroup required">
                                        <label htmlFor="locale" className="form-label">Translation</label>
                                        <input type="text"
                                               id="locale"
                                               name="locale"
                                               className="form-control"
                                               value={profileData.locale || ""}
                                               readOnly
                                        />
                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="site_name" className="form-label">Site name</label>
                                        <input type="site_name"
                                               id="site_name"
                                               name="site_name"
                                               className="form-control"
                                               value={profileData.site_name || ""}
                                               onChange={handleInput}
                                        />
                                        {errors.site_name && <em className="error hint" style={{color:'red'}}>{errors.site_name}</em>}

                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email"
                                               id="email"
                                               name="email"
                                               className="form-control"
                                               value={profileData.email || ""}
                                               onChange={handleInput}
                                        />
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <input type="text"
                                               id="address"
                                               name="address"
                                               className="form-control"
                                               value={profileData.address || ""}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="first_phone" className="form-label">First phone</label>
                                        <input type="text"
                                               id="first_phone"
                                               name="first_phone"
                                               className="form-control"
                                               value={profileData.first_phone || ""}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="second_phone" className="form-label">Second phone</label>
                                        <input type="text"
                                               id="second_phone"
                                               name="second_phone"
                                               className="form-control"
                                               value={profileData.second_phone || ""}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="whats_app" className="form-label">WhatsApp</label>
                                        <input type="text"
                                               id="whats_app"
                                               name="whats_app"
                                               className="form-control"
                                               value={profileData.whats_app || ""}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="facebook" className="form-label">FaceBook</label>
                                        <input type="text"
                                               id="facebook"
                                               name="facebook"
                                               className="form-control"
                                               value={profileData.facebook || ""}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="instagram" className="form-label">Instagram</label>
                                        <input type="text"
                                               id="instagram"
                                               name="instagram"
                                               className="form-control"
                                               value={profileData.instagram || ""}
                                               onChange={handleInput}
                                        />
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="youtube" className="form-label">YouTube</label>
                                        <input type="text"
                                               id="youtube"
                                               name="youtube"
                                               className="form-control"
                                               value={profileData.youtube || ""}
                                               onChange={handleInput}
                                        />
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="logo" className="form-label">Logo</label>
                                        <input type="file" name="logo"
                                               ref={logoImageRef}
                                               onChange={(e) =>setLogo(e.target.files)}
                                               className="form-control"/>
                                        <input type="hidden" name="current_logo"
                                               ref={currentLogoImage}
                                               onChange={(e)=>console.log('')}
                                               value={profileData.logo ===null  || profileData.logo ==="" ? "" : profileData.logo}/>
                                        {profileData.logo ===null || profileData.logo ==="" ? "":
                                            <div>
                                                <img  style={{width:'100px'}} src={`${imageUrl}logo/${profileData.logo}`}/>
                                                <a type='button' onClick={deleteLogoImage}>delete</a>
                                            </div>
                                        }
                                    </div>

                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>

    )
}
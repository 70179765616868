import {Link, useNavigate, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {
    baseURL,
    DELETE_PAGE_BANNER_IMAGE,
    DELETE_PAGE_BANNER_MOBILE_IMAGE,
    DELETE_PAGE_IMAGE,
    EDIT_PAGE, imageUrl,
    PAGE_DATA
} from "../Apis/Api";
import {Editor} from "@tinymce/tinymce-react";
export default function EditPage()
{

    const{id} = useParams();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [pageImage, setPageImage] = useState([]);
    const [pageBanner, setPageBanner] = useState([]);
    const [mobileBanner, setMobileBanner] = useState([]);
    const [pageSent, setPageSent] = useState(false);
    const navigate = useNavigate();
    const [selectedPage, setSelectedPage] = useState(0);
    const [pages, setPages] = useState([]);
    const [mceEditor , setMceEditor] =  useState(null);

    const [pageData , setPageData] = useState({
        title:"",
        heading_title:"",
        content:"",
        url:"",
        meta_title:"",
        meta_description:"",
        page_banner:"",
        mobile_banner:"",
        page_image:""

    });


    const [status , setStatusCheck] = useState(pageData.status === 1);
    const [noIndex , setNoIndexCheck] = useState(pageData.no_index === 1);
    const [inFooter , setInFooterCheck] = useState(pageData.in_footer === 1);
    const [hideFromNav , setHideFromNavCheck] = useState(pageData.hide_from_nav === 1);

    const pageImageRef = useRef();
    const pageBannerImageRef = useRef();
    const pageMobileBannerRef = useRef();

    const currentPageImage = useRef(null);
    const currentPageBannerImage = useRef(null);
    const currentMobileBanner = useRef(null);

    const setStatusFunction =(e)=>{
        e.persist();
        setStatusCheck(!status);
    }

    const setInFooterFunction = (e)=>{
        e.persist();
        setInFooterCheck(!inFooter);
    }


    const setNoIndexFunction = (e)=>{
        e.persist();
        setNoIndexCheck(!noIndex);
    }

    const setHideFromNavFunction = (e)=>{
        e.persist();
        setHideFromNavCheck(!hideFromNav);
    }


    const handleInput = (e) => {
        e.persist();
        setPageData({...pageData, [e.target.name]:e.target.value });

    }

    const pageNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const fillUrl =  () => {

        let name = pageNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="" && locale!=='0') {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setPageData({...pageData, ['url']: NSlocale + '/' + NSname});
        }else{
            metaUrl.current.value = "";
            setPageData({...pageData, ['url']: ""});
        }
    }

    const fillMetaTitle = ()=> {
        let name = pageNameRef.current.value;
        if(name !=="")
        {
            let siteName = 'androas';
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            setPageData({...pageData, ['meta_title']: NSname + ' | ' + siteName});

        }else{
            metaTitle.current.value = "";
            setPageData({...pageData, ['meta_title']: ""});
        }
    }
    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${PAGE_DATA}/${id}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setMceEditor(res.data.dataPage.content)
                setPages(res.data.pages)
                setSelectedPage(res.data.dataPage.parent)
                setPageData(res.data.dataPage)
                setStatusCheck(res.data.dataPage.status)
                setInFooterCheck(res.data.dataPage.in_footer)
                setNoIndexCheck(res.data.dataPage.no_index)
                setHideFromNavCheck(res.data.dataPage.hide_from_nav)
            });
        };
        getData();
    }, [pageSent]);

    const addPage = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(pageData));

        if(pageData.title==="" || pageData.meta_title==="" || pageData.url==="") {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('parent_id', selectedPage);
            formData.append('title', pageData.title || "");
            formData.append('heading_title', pageData.heading_title || "");
            formData.append('meta_title', pageData.meta_title || "");
            formData.append('meta_description', pageData.meta_description || "");
            formData.append('url', pageData.url || "");
            formData.append('content', mceEditor || "");
            formData.append('page_image',pageImage[0]);
            formData.append('current_page_image',currentPageImage.current.value);

            formData.append('page_banner',pageBanner[0]);
            formData.append('current_page_banner',currentPageBannerImage.current.value);
            formData.append('mobile_banner',mobileBanner[0]);
            formData.append('current_mobile_banner',currentMobileBanner.current.value);
            formData.append('no_index', noIndex ? '1':'0');
            formData.append('in_footer', inFooter ? '1':'0');
            formData.append('hide_from_nav', hideFromNav ? '1':'0');
            formData.append('status', status ? '1':'0');

            axios.post(`${baseURL}/${EDIT_PAGE}/${id}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                if(res.data.status === 200)
                {
                    swal("Success",res.data.message,"success");
                    navigate('/pages-list' , {replace:true});
                }
                else{
                    swal("Warning",res.data.error,"warning");

                }
            });
        }
    }


    const deletePageImage=() => {
        axios.post(`${baseURL}/${DELETE_PAGE_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            swal("Success",res.data.message,"success");
            setPageSent(!pageSent)

        })
    }

    const deletePageBanner=() => {
        axios.post(`${baseURL}/${DELETE_PAGE_BANNER_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            swal("Success",res.data.message,"success");
            setPageSent(!pageSent)

        })
    }

    const deleteMobileBanner=() => {
        axios.post(`${baseURL}/${DELETE_PAGE_BANNER_MOBILE_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            swal("Success",res.data.message,"success");
            setPageSent(!pageSent)
        })
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addPage}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Edit page</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/pages-list">Pages table</Link></li>
                                        <li><Link to="#">Edit page</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Page Information</h2>
                                    <div className="formGroup required">
                                        <label htmlFor="locale" className="form-label">Language</label>
                                        <input type="text"
                                               ref={localeRef}
                                               id="locale"
                                               name="locale"
                                               readOnly
                                               className="form-control"
                                               value={pageData.locale || ""}
                                               onChange={handleInput}
                                        />
                                    </div>
                                    <div className="formGroup required">
                                        <label htmlFor="parent_id">Select page</label>
                                        <select
                                            className="form-control"
                                            data-live-search="true" name="parent_id" id="parent_id"
                                            defaultValue={pageData.parent}
                                            value={selectedPage}
                                            onChange={(e)=>setSelectedPage(e.target.value)}>
                                            <option value='0'>select page</option>
                                            {pages.map((page,index)=>
                                                <option key={index} value={page.id}>{page.title}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="formGroup required">
                                        <label htmlFor="title" className="form-label">Page title</label>
                                        <input type="text"
                                               ref={pageNameRef}
                                               id="title"
                                               name="title"
                                               className="form-control"
                                               value={pageData.title || ""}
                                               onChange={handleInput}
                                        />
                                        {errors.title && <em className="error hint" style={{color:'red'}}>{errors.title}</em>}
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="heading_title" className="form-label">Heading title</label>
                                        <input type="text"
                                               id="heading_title"
                                               name="heading_title"
                                               className="form-control"
                                               value={pageData.heading_title || ""}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    {/*<div className="formGroup">*/}
                                    {/*    <label htmlFor="content" className="form-label">Page Content</label>*/}
                                    {/*    <input type="text"*/}
                                    {/*           id="content"*/}
                                    {/*           name="content"*/}
                                    {/*           className="form-control"*/}
                                    {/*           value={pageData.content || ""}*/}
                                    {/*           onChange={handleInput}*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                    <div className="formGroup quarter required">
                                        <label htmlFor="url"  className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="url"
                                            id="url"
                                            value={pageData.url || ""}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.url && <em className="error hint" style={{color:'red'}}>{errors.url}</em>}
                                    </div>

                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={pageData.meta_title || ""}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                    </div>


                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={pageData.meta_description || ""}
                                            onChange={handleInput}
                                        />
                                    </div>



                                    <div className="formGroup">
                                        <label htmlFor="page_image" className="form-label">Page image</label>
                                        <input type="file" name="page_image"
                                               ref={pageImageRef}
                                               onChange={(e) =>setPageImage(e.target.files)}
                                               className="form-control"/>
                                        <input type="hidden" name="current_page_image"
                                               ref={currentPageImage}
                                               onChange={(e)=>console.log('')}
                                               value={pageData.page_image ===null  || pageData.page_image ==="" ? "" : pageData.page_image}/>
                                        {pageData.page_image ===null || pageData.page_image ==="" ? "":
                                            <div>
                                                <img  style={{width:'100px'}}  src={`${imageUrl}pages/${pageData.page_image}`}/>
                                                <a type='button' onClick={deletePageImage}>delete</a>
                                            </div>
                                        }
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="page_banner" className="form-label">Banner image</label>
                                        <input type="file" name="page_banner"
                                               ref={pageBannerImageRef}
                                               onChange={(e) =>setPageBanner(e.target.files)}
                                               className="form-control"/>
                                        <input type="hidden" name="current_page_banner"
                                               ref={currentPageBannerImage}
                                               onChange={(e)=>console.log('')}
                                               value={pageData.page_banner ===null  || pageData.page_banner ==="" ? "" : pageData.page_banner}/>
                                        {pageData.page_banner ===null || pageData.page_banner ==="" ? "":
                                            <div>
                                                <img  style={{width:'100px'}}src={`${imageUrl}pages/${pageData.page_banner}`}/>
                                                <a type='button' onClick={deletePageBanner}>delete</a>
                                            </div>
                                        }
                                    </div>

                                    <div className="formGroup">
                                    <label htmlFor="mobile_banner" className="form-label">Mobile image</label>
                                    <input type="file" name="mobile_banner"
                                           ref={pageMobileBannerRef}
                                           onChange={(e) =>setMobileBanner(e.target.files)}
                                           className="form-control"/>
                                    <input type="hidden" name="current_mobile_banner"
                                           ref={currentMobileBanner}
                                           onChange={(e)=>console.log('')}
                                           value={pageData.mobile_banner ===null  || pageData.mobile_banner ==="" ? "" : pageData.mobile_banner}/>
                                    {pageData.mobile_banner ===null || pageData.mobile_banner ==="" ? "":
                                        <div>
                                            <img  style={{width:'100px'}} src={`${imageUrl}pages/${pageData.mobile_banner}`}/>
                                            <a type='button' onClick={deleteMobileBanner}>delete</a>
                                        </div>
                                    }
                                </div>


                                    <div className="formGroup">
                                        <label htmlFor="page_content" className="form-label">Page Content</label>
                                        <Editor
                                        apiKey='kds61advbpqoiaoaf8d490hcgwr9ula3jelsk1l0xwgq4ixh'
                                            id="page_content"
                                            value={mceEditor}
                                            onEditorChange={setMceEditor}
                                        />
                                    </div>

                                    <div className="formGroup wide checkGroup">
                                <span>
                                    <input  className="form-check-input" name="status"
                                            checked={status}
                                            onChange={setStatusFunction}
                                            id="status" type="checkbox"/>
                                    <label htmlFor="status" className="form-check-label">Status</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="no_index"
                                            checked={noIndex}
                                            onChange={setNoIndexFunction}
                                            id="no_index" type="checkbox"/>
                                    <label htmlFor="no_index" className="form-check-label">No index</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="in_footer"
                                            checked={inFooter}
                                            onChange={setInFooterFunction}
                                            id="in_footer" type="checkbox"/>
                                    <label htmlFor="in_footer" className="form-check-label">In footer</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="hide_from_nav"
                                            checked={hideFromNav}
                                            onChange={setHideFromNavFunction}
                                            id="hide_from_nav" type="checkbox"/>
                                    <label htmlFor="hide_from_nav" className="form-check-label">Hide from navigation</label>
                                </span>


                                    </div>


                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                                <div className="functionBar">
                                    <div className="col-auto fanctions">
                                        <Link to="" className="fancItem alert-success">
                                            <i className="bi bi-card-list"></i><span>customers</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-warning">
                                            <i className="bi bi-node-plus"></i><span>add_supplier</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-primary">
                                            <i className="bi bi-node-plus"></i><span>create_account</span>
                                        </Link>
                                    </div>
                                    <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
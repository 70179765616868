import {useContext, useState} from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import {UserContext} from "./Context/Context";
import Validation from "../Validation/Validation";
import Cookies from "universal-cookie";
import {baseURL, REGISTER} from "../Apis/Api";

export default function SignIn ()
{
    const [userName , setUserName] = useState('');
    const [email , setEmail] = useState('');
    const [password , setPassword] = useState('');
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();
    const userNow = useContext(UserContext);
    //Cookie
    const cookie = new Cookies();

    async function SignInSubmit(e)
    {
        e.preventDefault();
        setErrors(Validation({user_name : userName , email: email , password : password}));
        try {
          let resp =   await axios.post(`${baseURL}/${REGISTER}`, {
                user_name: userName,
                email: email,
                password: password
            });
            const token = resp.data.token;
            cookie.set('Bearer ',token);
            const userDetails = resp.data.user;
            userNow.setAuth({token , userDetails});
            navigate('/dashboard' , {replace:true});
        }catch (errors)
        {
            if(errors.response.status ===422)
            {
               console.log(errors.response.status)

            }
        }
    }
    return (
        <main id="homeMain" tabIndex="-1">
            <div className="container-fluid">
                <div className="row">
                    <div className="mainBody">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-center">
                                <div className="col-auto">
                                    <form onSubmit={SignInSubmit}>
                                        <p><strong>SignIn</strong><i className="bi bi-person-workspace"></i><br/>Samatek account</p>
                                        <div>

                                            <div className="formGroup">
                                                <label className="" htmlFor="user_name"><i className="bi bi-person"></i><span className="visually-hidden">UserName</span></label>
                                                <input id="user_name" type="user_name" tabIndex="1"
                                                       className="form-control"
                                                       name="user_name" value={userName}
                                                       autoFocus placeholder="UserName"
                                                       onChange={(e)=>setUserName(e.target.value)}/>
                                                {errors.user_name && <em className="error hint" style={{color:'red'}}>{errors.user_name}</em>}

                                            </div>

                                            <div className="formGroup">
                                                <label className="" htmlFor="email"><i className="bi bi-person"></i><span className="visually-hidden">email</span></label>
                                                <input id="email" type="email" tabIndex="1"
                                                       className="form-control"
                                                       name="email" value={email}
                                                       autoFocus placeholder="E-mail"
                                                       onChange={(e)=>setEmail(e.target.value)}/>
                                                {errors.email && <em className="error hint" style={{color:'red'}}>{errors.email}</em>}
                                            </div>
                                            <div className="formGroup">
                                                <label className="" htmlFor="password">
                                                    <input type="checkbox" className="eye" />
                                                    <i className="bi bi-eye"></i><span
                                                    className="visually-hidden">Password</span>
                                                </label>
                                                <input id="password" tabIndex="2" type="password"
                                                       className="form-control"
                                                       value={password}
                                                       onChange={(e)=>setPassword(e.target.value)}
                                                       name="password" autoComplete="current-password"
                                                       placeholder="Password"/>
                                                {errors.password && <em className="error hint" style={{color:'red'}}>{errors.password}</em>}
                                            </div>
                                            <div className="formGroup check">
                                                <input className="form-check-input" type="checkbox" name="remember"/>
                                                <label className="" htmlFor="remember">remember_me</label>
                                            </div>
                                            <div className="formGroup">
                                                <button type="submit" className="bttn black">Sign in
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
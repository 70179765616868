import {useRef, useState, useEffect} from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Cookies from "universal-cookie";
import Validation from "../Validation/Validation";
import {baseURL, LOGIN} from "../Apis/Api";
export default function LogIn ()
{
    const [email , setEmail] = useState('');
    const [password , setPassword] = useState('');
    const [errors , setErrors] = useState({});
    const navigate = useNavigate();
    const cookies = new Cookies();
    const passwordRef = useRef('');

    useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add('loginPage');
    }, []);                                                                                                                                                         
    const myFunction = ()=> {
        let password = passwordRef.current;
        if (password.type === "password") {
            password.type = "text";
        } else {
            password.type = "password";
        }
    }

    async function LogInSubmit(e) {
        e.preventDefault();
        setErrors(Validation({
           email: email , password : password
        }));
        if(email!=="" && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&   password.length>=8)
        {
            try {
                let resp =   await axios.post( `${baseURL}/${LOGIN}`, {
                    email: email,
                    password: password
                });
                const token = resp.data.token;
                cookies.set('Bearer',token);
             //   const userDetails = resp.data.user;
                // user.setAuth({token , userDetails});
                navigate('/dashboard' , {replace:true});
            }catch (error)
            {
                if(error.response.status ===401) {
                  setErrors(Validation({
                      email:email,
                      password:password,
                      unauthorized:'unauthorized'
                  }));

                }
            }
        }
        }

    return (
        <main id="homeMain" tabIndex="-1">
            <div className="container-fluid">
                <div className="row">
                    <div className="mainBody">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-center">
                                <div className="col-auto">
                                    <form id="login_form" onSubmit={LogInSubmit}>
                                        <p><strong>LogIn</strong><i className="bi bi-person-workspace"></i><br/>Samatek account</p>
                                        <div>
                                            <div className="formGroup">
                                                <label  htmlFor="email"><i className="bi bi-person"></i><span className="visually-hidden">email</span></label>
                                                <input id="email" type="text" tabIndex="1"
                                                       className="form-control"
                                                       name="email" value={email}
                                                       autoFocus placeholder="E-mail"
                                                       onChange={(e)=>setEmail(e.target.value)}/>
                                                {errors.email && <em className="error hint" style={{color:'red'}}>{errors.email}</em>}
                                                {errors.unauthorized  && <em className="error hint" style={{color:'red'}}>{errors.unauthorized} </em>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="password">
                                                    <input type="checkbox" className="eye"  onClick={myFunction}/>
                                                    <i className="bi bi-eye"></i><span
                                                    className="visually-hidden">Password</span>
                                                </label>
                                                <input id="password" tabIndex="2" type="password"
                                                       className="form-control"
                                                       value={password}
                                                       ref={passwordRef}
                                                       onChange={(e)=>setPassword(e.target.value)}
                                                       name="password" autoComplete="current-password"
                                                       placeholder="Password"/>
                                                {errors.password && <em className="error hint" style={{color:'red'}}>{errors.password}</em>}
                                            </div>
                                            <div className="formGroup check">
                                                <input className="form-check-input" type="checkbox" name="remember"/>
                                                <label className="" htmlFor="remember">remember_me</label>
                                            </div>
                                            <div className="formGroup">
                                                <button type="submit" className="bttn black">Login
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
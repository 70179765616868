import Content from "./CMS/Layouts/Content";
import {Routes , Route} from 'react-router-dom'
import AddCategory from "./CMS/Categories/AddCategory";
import AddBrand from "./CMS/Brands/AddBrand";
import Login from "./CMS/Auth/LogIn";
import CategoriesList from "./CMS/Categories/CategoriesList";
import EditCategory from "./CMS/Categories/EditCategory";
import EditBrand from "./CMS/Brands/EditBrand";
import BrandsList from "./CMS/Brands/BrandsList";
import SignIn from "./CMS/Auth/SignIn";
import RequireAuth from "./CMS/Auth/RequireAuth";
import UsersList from "./CMS/Users/UsersList";
import AddUser from "./CMS/Users/AddUser";
import EditUser from "./CMS/Users/EditUser";
import AddProduct from "./CMS/Products/AddProduct";
import EditProduct from "./CMS/Products/EditProduct";
import ProductsList from "./CMS/Products/ProductsList";
import AddCategoryTranslation from "./CMS/Categories/AddCategoryTranslation";
import EditCategoryTranslation from "./CMS/Categories/EditCategoryTranslation";
import AddBrandTranslation from "./CMS/Brands/AddBrandTranslation";
import EditBrandTranslation from "./CMS/Brands/EditBrandTranslation";
import AddProductTranslation from "./CMS/Products/AddProductTranslation";
import EditProductTranslation from "./CMS/Products/EditProductTranslation";
import AddProfile from "./CMS/Profile/AddProfile";
import AddProfileTranslation from "./CMS/Profile/AddProfileTranslation";
import EditProfileTranslation from "./CMS/Profile/EditProfileTranslation";
import Profile from "./CMS/Profile/Profile";
import AddPage from "./CMS/Pages/AddPage";
import PagesList from "./CMS/Pages/PagesList";
import AddPageTranslation from "./CMS/Pages/AddPageTranslation";
import EditPageTranslation from "./CMS/Pages/EditPageTranslation";
import EditPage from "./CMS/Pages/EditPage";
function App() {
  return (
    <div>
        <Routes>
                                        {/*BACKOFFICE ROUTES*/}
            <Route path="sign-in" element={<SignIn/>}/>
            <Route path='/backoffice' element={<Login/>}/>

                {/*Protected Routes*/}
               <Route element={<RequireAuth/>}>
                   <Route path="/dashboard" element={ <Content/>}/>

                    {/*Users*/}
                   <Route path='users-list' element={<UsersList/>}/>
                   <Route path='add-user' element={<AddUser/>}/>
                   <Route path='edit-user/:id' element={<EditUser/>}/>

                   {/*Categories*/}
                   <Route path='add-category'  element={<AddCategory/>}/>
                   <Route path='edit-category/:id' element={<EditCategory/>}/>
                   <Route path='categories-list' element={<CategoriesList/>}/>
                   <Route path='add-category-translation/:categoryId' element={<AddCategoryTranslation/>}/>
                   <Route path='edit-category-translation/:id/:categoryId' element={<EditCategoryTranslation/>}/>

                   {/*Brands*/}
                   <Route path='add-brand' element={<AddBrand/>}/>
                   <Route path='edit-brand/:id' element={<EditBrand/>}/>
                   <Route path='brands-list' element={<BrandsList/>}/>
                   <Route path='add-brand-translation/:brandId' element={<AddBrandTranslation/>}/>
                   <Route path='edit-brand-translation/:id/:brandId' element={<EditBrandTranslation/>}/>

                   {/*Products */}
                   <Route path='add-product' element={<AddProduct/>}/>
                   <Route path='edit-product/:id' element={<EditProduct/>}/>
                   <Route path='products-list' element={<ProductsList/>}/>
                   <Route path='add-product-translation/:productId' element={<AddProductTranslation/>}/>
                   <Route path='edit-product-translation/:id/:productId' element={<EditProductTranslation/>}/>

                   {/*Profile*/}
                   <Route path='profile' element={<Profile/>}/>
                   <Route path='add-profile' element={<AddProfile/>}/>
                   <Route path='add-profile-translation/:profileId' element={<AddProfileTranslation/>}/>
                   <Route path='edit-profile-translation/:id/:profileId' element={<EditProfileTranslation/>}/>

                   {/*Pages*/}
                   <Route path='pages-list' element={<PagesList/>}/>
                   <Route path='add-page' element={<AddPage/>}/>
                   <Route path='edit-page/:id' element={<EditPage/>}/>
                   <Route path='add-page-translation/:pageId' element={<AddPageTranslation/>}/>
                   <Route path='edit-page-translation/:id/:pageId' element={<EditPageTranslation/>}/>
               </Route>
        </Routes>
    </div>
  );
}

export default App;

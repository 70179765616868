import {Link, useNavigate} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import {Editor} from "@tinymce/tinymce-react";
import Cookies from "universal-cookie";
import {
    ADD_PRODUCT,
    baseURL,
    BRAND_SELECT_LIST, CATEGORIES_TABLE,
    CURRENT_LOCALE,
} from "../Apis/Api";
export default function AddProduct()
{

    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [selectedCategoryValue , setSelectedCategoryValue] = useState(0);
    const [selectedBrandValue , setSelectedBrandValue] = useState(0);
    const [productImage, setProductImage] = useState([]);
    const [productSent , setProductSent] = useState(false);
    const [mceEditor , setMceEditor] =  useState(null);
    const categoryRef = useRef();
    const brandRef = useRef();

    const [locale , setLocale] = useState('');
    const navigate = useNavigate();
    const [allCheckBox , setCheckBoxes] = useState({
        featured:false,
        best_seller:false,
        no_index:false,
        hide_from_nav:false,
        status:false,
    });
    const [productData , setProductData] = useState({
        product_id:"",
        product_name:"",
        code:"",
        barcode:"",
        product_content:"",
        product_url:"",
        meta_title:"",
        meta_description:"",
    });

    const productNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const fillUrl =  () => {

        let name = productNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="" && locale!=="") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setProductData({...productData, ['product_url']: NSlocale + '/' + NSname});

            productData.product_url = NSlocale + '/' + NSname;;
        }
    }

const fillMetaTitle = ()=> {
    let name = productNameRef.current.value;
    if(name !=="")
    {
        let siteName = 'androas';
        let NSname = name.replace(/\s+/g, '-').toLowerCase();
        metaTitle.current.value = NSname + ' | ' + siteName;
        productData.meta_title = NSname + ' | ' + siteName;
    }
}

    const productImageRef = useRef();
    async function handleCheckBox (e) {
        e.persist();
        setCheckBoxes({
            ...allCheckBox , [e.target.name]:e.target.checked
        });
    };

    const handleInput = (e) => {
        e.persist();
        setProductData({...productData, [e.target.name]:e.target.value });

    }



    useEffect(() => {
        const getData = async () => {

            await axios.get(`${baseURL}/${CATEGORIES_TABLE}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setCategoryList(res.data[0])
            });

            await axios.get(`${baseURL}/${BRAND_SELECT_LIST}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setBrandList(res.data[0])
            });

            await axios.get(`${baseURL}/${CURRENT_LOCALE}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setLocale(res.data.locale);
            });
        };
        getData();
    }, [productSent]);

    const addProduct = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(productData  , selectedBrandValue , selectedCategoryValue , null));

        if(productData.product_name==="" || productData.product_url==="" || productData.meta_title==="" || selectedCategoryValue ===0 || selectedBrandValue ===0) {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('category_id', selectedCategoryValue);
            formData.append('brand_id', selectedBrandValue);
            formData.append('product_name', productData.product_name ||"");
            formData.append('product_content', mceEditor || "");
            formData.append('product_url', productData.product_url || "");
            formData.append('meta_title', productData.meta_title || "");
            formData.append('meta_description', productData.meta_description || "");
            formData.append('product_image',productImage[0]);
            formData.append('featured', allCheckBox.featured ? '1':'0');
            formData.append('best_seller', allCheckBox.best_seller ? '1':'0');
            formData.append('no_index', allCheckBox.no_index ? '1':'0');
            formData.append('hide_from_nav', allCheckBox.hide_from_nav ? '1':'0');
            formData.append('status', allCheckBox.status ? '1':'0');

            axios.post(`${baseURL}/${ADD_PRODUCT}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                if(res.data.status === 200) {
                    //Reset Inputs After Submit
                    setProductData({
                        product_id:"",
                        product_name:"",
                        code:"",
                        barcode: "",
                        product_content:"",
                        product_url:"",
                        meta_title:"",
                        meta_description:"",
                        locale: ""
                    });
                    setCheckBoxes({
                        featured:false,
                        best_seller:false,
                        no_index:false,
                        hide_from_nav:false,
                        status:false
                    });
                    productImageRef.current.value = "";
                    categoryRef.current.value = 0;
                    brandRef.current.value = 0;
                    setProductSent(!productSent);
                    swal("Success",res.data.message,"success");
                    navigate('/products-list' , {replace:true});
                }else{
                    swal("Warning",res.data.error,"warning");

                }
            });
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addProduct}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>New product</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/products-list">Products table</Link></li>
                                        <li><Link to="#">New product</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Product Information</h2>
                                    <div className="formGroup required">
                                        <input hidden  readOnly   ref={localeRef} value={locale} onChange={()=>console.log('')}/>
                                        <label>Select category</label>
                                        <select
                                            className="form-control"
                                            data-live-search="true" name="category_id" id="category_id" ref={categoryRef}
                                            onChange={(e)=>setSelectedCategoryValue(e.target.value)}>
                                            <option value="0">select category</option>
                                            {categoryList.map((category,index)=>
                                                <option key={index} value={category.id}>
                                                    {category.parent_id>0?   `${category.category_name} --` : category.category_name }</option>
                                                )}
                                        </select>
                                        {errors.category_id && <em className="error hint" style={{color:'red'}}>{errors.category_id}</em>}
                                    </div>

                                    <div className="formGroup required">
                                        <label>Select brand</label>
                                        <select
                                            className="form-control"
                                            data-live-search="true" name="brand_id" id="brand_id" ref={brandRef}
                                            onChange={(e)=>setSelectedBrandValue(e.target.value)}>
                                            <option value="0">select brand</option>
                                            {brandList.map((brand,index)=>
                                                <option key={index} value={brand.id}>{brand.brand_name}</option>
                                            )}
                                        </select>
                                        {errors.brand_id && <em className="error hint" style={{color:'red'}}>{errors.brand_id}</em>}
                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="product_name" className="form-label">Product name</label>
                                        <input type="text"
                                               ref={productNameRef}
                                               id="product_name"
                                               name="product_name"
                                               className="form-control"
                                               value={productData.product_name}
                                               onChange={handleInput}
                                        />
                                        {errors.product_name && <em className="error hint" style={{color:'red'}}>{errors.product_name}</em>}
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="code" className="form-label">Product code</label>
                                        <input type="text"
                                               id="code"
                                               name="code"
                                               className="form-control"
                                               value={productData.code}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="barCode" className="form-label">Product barcode</label>
                                        <input type="text"
                                               id="barcode"
                                               name="barcode"
                                               className="form-control"
                                               value={productData.barcode}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup quarter required">
                                        <label htmlFor="product_url"  className="form-label">Url</label>
                                        <input
                                            className="form-control"
                                            ref={metaUrl}
                                            name="product_url"
                                            id="product_url"
                                            value={productData.product_url}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.product_url && <em className="error hint" style={{color:'red'}}>{errors.product_url}</em>}
                                    </div>


                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            className="form-control"
                                            ref={metaTitle}
                                            name="meta_title"
                                            id="meta_title"
                                            value={productData.meta_title}
                                            onChange={handleInput}
                                        />
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                    </div>


                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={productData.meta_description}
                                            onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="product_content" className="form-label">Product Content</label>
                                        <Editor
                                            apiKey='kds61advbpqoiaoaf8d490hcgwr9ula3jelsk1l0xwgq4ixh'
                                            id="product_content"
                                            value={mceEditor}
                                            onEditorChange={setMceEditor}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="product_image" className="form-label">Image 500 X 500</label>
                                        <input type="file" name="product_image"
                                               ref={productImageRef}
                                               onChange={(e) =>setProductImage(e.target.files)}
                                               className="form-control"/>
                                    </div>

                                    <div className="formGroup wide checkGroup">
                                <span>
                                    <input  className="form-check-input" name="status"
                                            checked={allCheckBox.status}
                                            onChange={handleCheckBox}
                                            id="status" type="checkbox"/>
                                    <label htmlFor="status" className="form-check-label">Status</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="featured"
                                            checked={allCheckBox.featured}
                                            onChange={handleCheckBox}
                                            id="featured" type="checkbox"/>
                                    <label htmlFor="featured" className="form-check-label">Featured</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="best_seller"
                                            checked={allCheckBox.best_seller}
                                            onChange={handleCheckBox}
                                            id="best_seller" type="checkbox"/>
                                    <label htmlFor="best_seller" className="form-check-label">Best seller</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="no_index"
                                            checked={allCheckBox.no_index}
                                            onChange={handleCheckBox}
                                            id="no_index" type="checkbox"/>
                                    <label htmlFor="no_index" className="form-check-label">No index</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="hide_from_nav"
                                            checked={allCheckBox.hide_from_nav}
                                            onChange={handleCheckBox}
                                            id="hide_from_nav" type="checkbox"/>
                                    <label htmlFor="hide_from_nav" className="form-check-label">Hide from navigation</label>
                                </span>


                                    </div>


                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                                <div className="functionBar">
                                    <div className="col-auto fanctions">
                                        <Link to="" className="fancItem alert-success">
                                            <i className="bi bi-card-list"></i><span>customers</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-warning">
                                            <i className="bi bi-node-plus"></i><span>add_supplier</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-primary">
                                            <i className="bi bi-node-plus"></i><span>create_account</span>
                                        </Link>
                                    </div>
                                    <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {baseURL, EXPORT_DEFAULT_LANGUAGE, PROFILE_DATA} from "../Apis/Api";

export default function Profile ()
{

    const [profile, setProfile] = useState([]);
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [changeLang , setChangeLang] = useState(false);

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${PROFILE_DATA}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setProfile(res.data.profileData)
            });
        };
        getData();
    }, [changeLang]);

    async function changeDefaultLanguage(id)
    {

        await axios.post(`${baseURL}/${EXPORT_DEFAULT_LANGUAGE}/${id}`, null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            if(res.data.status===200)
            {
                setChangeLang(!changeLang);
            }

        });
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <div className="row bodyHeader">
                            <div className="col-auto">
                                <h1>Profile</h1>
                                <ul className="breadcrumb">
                                    <li><Link to="/dashboard">Dashboard</Link></li>
                                    <li><Link to="">Profile</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mainBody">
                                <table className="table table-striped dataTable no-footer" id="samatek-datatable" aria-describedby="samatek-datatable_info"
                                       data-table-max-column="6">
                                    <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Locale</th>
                                        <th>Site name</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <th>Default language</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {profile.map((data , index)=>

                                            <tr key={index}>
                                                <td>{data.id}</td>
                                                <td><Link to={`/edit-profile-translation/${data.id}/1`}>{data.locale}</Link></td>
                                                <td>{data.site_name}</td>
                                                <td>{data.email}</td>
                                                <td>{data.address}</td>
                                                <td>{data.default_language === 1 ?
                                                    <button onClick={()=>changeDefaultLanguage(data.id)}><i className="bi bi-x-lg"></i></button> :
                                                    <button  onClick={()=>changeDefaultLanguage(data.id)}><i className="bi bi-check-circle-fill"></i></button>
                                                }
                                                </td>
                                                    <td>
                                                </td>
                                            </tr>
                                    )}

                                    </tbody>
                                </table>
                                <span className="function">
                                          <Link className="alert-success" title="add"  to={`/add-profile-translation/1`}>
                                              <i className="bi bi-node-plus"><span>Add translation</span></i>
                                          </Link>
                                      </span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
import {Link, useNavigate, useParams} from 'react-router-dom'
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Validation from "../Validation/Validation";
import swal from "sweetalert";
import {baseURL, CREATE_CATEGORY_TRANSLATION, UNUSED_CATEGORY_TRANSLATION} from "../Apis/Api";

export default function AddCategoryTranslation ()
{
    const {categoryId} = useParams();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [translations , setTranslations] = useState([]);
    const [selectedTranslationValue , setSelectedTranslationValue] = useState(0);
    const selectRef = useRef('');
    const navigate = useNavigate();

    const categoryNameRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const [categoryData , setCategoryData] = useState({
        category_name:"",
        category_content:"",
        category_url:"",
        meta_title:"",
        meta_description:"",
    });

    const fillUrl =  () => {

        let name = categoryNameRef.current.value;
        let locale = selectRef.current.value;
        if(name !=="" && locale!=="0") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setCategoryData({...categoryData, ['category_url']: NSlocale + '/' + NSname });
        }else{
            metaUrl.current.value = "";
            setCategoryData({...categoryData, ['category_url']:"" });
        }
    }

    const fillMetaTitle = ()=> {
        let name = categoryNameRef.current.value;
        let siteName = 'androas';
        if(name!=="")
        {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            setCategoryData({...categoryData, ['meta_title']: NSname + ' | ' + siteName });
        }
        else{
            metaTitle.current.value = "";
            setCategoryData({...categoryData, ['meta_title']: "" });
        }
    }


    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${UNUSED_CATEGORY_TRANSLATION}/${categoryId}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setTranslations(res.data[0])
            });
        };
        getData();
    }, []);


    const handleInput = (e) => {
        e.persist();
        setCategoryData({...categoryData, [e.target.name]:e.target.value });
    }

    const addCategoryTranslation = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(categoryData , null , null , selectedTranslationValue));

        if(categoryData.category_name==="" || categoryData.category_url==="" || categoryData.meta_title==="" || selectedTranslationValue ===0) {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('category_id', categoryId);
            formData.append('category_name', categoryData.category_name);
            formData.append('category_content', categoryData.category_content || "");
            formData.append('category_url', categoryData.category_url);
            formData.append('meta_title', categoryData.meta_title);
            formData.append('meta_description', categoryData.meta_description || "");
            formData.append('locale', selectedTranslationValue);

            axios.post(`${baseURL}/${CREATE_CATEGORY_TRANSLATION}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                if(res.data.status === 200) {
                    swal("Success",res.data.message,"success");
                    navigate('/categories-list' , {replace:true});
                }else
                {
                    swal("Warning",res.data.error,"warning");
                }

                }
            );
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addCategoryTranslation}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Add translation</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/categories-list">Categories table</Link></li>
                                        <li><Link to="#">Add translation</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Add Translation</h2>

                                    <div className="formGroup required">
                                        <label htmlFor="translation" className="form-label">Translation</label>
                                        <select
                                            className="form-control"
                                            ref={selectRef}
                                            value={selectedTranslationValue}
                                            data-live-search="true" name="selectedTranslation" id="selectedTranslation"
                                            onChange={(e)=>setSelectedTranslationValue(e.target.value)}
                                        >
                                            <option value='0'>select translation</option>
                                            {translations.map((translation,index)=>
                                                <option key={index}  value={translation}>{translation}</option>
                                            )}
                                        </select>
                                        {errors.selectedTranslation && <em className="error hint" style={{color:'red'}}>{errors.selectedTranslation}</em>}

                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="category_name" className="form-label">Category name</label>
                                        <input type="text"
                                               ref={categoryNameRef}
                                               id="category_name"
                                               name="category_name"
                                               className="form-control"
                                               value={categoryData.category_name}
                                               onChange={handleInput}
                                        />
                                        {errors.category_name && <em className="error hint" style={{color:'red'}}>{errors.category_name}</em>}
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="category_content" className="form-label">Category Content</label>
                                        <input type="text"
                                               id="category_content"
                                               name="category_content"
                                               className="form-control"
                                               value={categoryData.category_content}
                                               onChange={handleInput}
                                        />

                                    </div>

                                    <div className="formGroup quarter required">
                                        <label htmlFor="category_url" className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="category_url"
                                            id="category_url"
                                            value={categoryData.category_url}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.category_url && <em className="error hint" style={{color:'red'}}>{errors.category_url}</em>}
                                    </div>


                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={categoryData.meta_title}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                    </div>

                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={categoryData.meta_description}
                                            onChange={handleInput}
                                        />
                                    </div>

                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
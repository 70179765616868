import {Link, useNavigate, useParams} from 'react-router-dom'
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {useEffect, useState} from "react";
import axios from "axios";
import Validation from "../Validation/Validation";
import swal from "sweetalert";
import {baseURL, CREATE_PROFILE_TRANSLATION, UNUSED_PROFILE_TRANSLATION} from "../Apis/Api";

export default function AddProfileTranslation ()
{
    const {profileId} = useParams();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [sentTranslation , setSentTranslation] = useState(false);
    const [translations , setTranslations] = useState([]);
    const [selectedTranslationValue , setSelectedValue] = useState(0);
    const [profileData , setProfileData] = useState({
        title:'',
        site_name:'',
        email:'',
        address:'',
        first_phone:'',
        second_phone:'',
        whats_app:'',
        facebook:'',
        youtube:'',
        instagram:'',
    });

    const navigate = useNavigate();

    const [logo , setLogo] = useState([]);

    const handleInput = (e) => {
        e.persist();
        setProfileData({...profileData, [e.target.name]:e.target.value });

    }


    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${UNUSED_PROFILE_TRANSLATION}/${profileId}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setTranslations(res.data[0])
            });
        };
        getData();
    }, [sentTranslation]);



    const addProfileTranslation = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(profileData , null , null , selectedTranslationValue));

        if(selectedTranslationValue ===0 || profileData.site_name==="") {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {   e.preventDefault();
            const formData = new FormData();
            formData.append('title', profileData.title || "");
            formData.append('profile_id', profileId);
            formData.append('site_name', profileData.site_name || "");
            formData.append('email', profileData.email || "");
            formData.append('address', profileData.address || "");
            formData.append('first_phone', profileData.first_phone || "");
            formData.append('second_phone', profileData.second_phone || "");
            formData.append('whats_app', profileData.whats_app || "");
            formData.append('facebook', profileData.facebook || "");
            formData.append('youtube', profileData.youtube || "");
            formData.append('instagram', profileData.instagram || "");
            formData.append('locale', selectedTranslationValue);
            formData.append('language', selectedTranslationValue);
            formData.append('logo', logo[0]);
            axios.post(`${baseURL}/${CREATE_PROFILE_TRANSLATION}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                    if(res.data.status === 200) {
                        //Reset Inputs After Submit
                        setProfileData({
                            title:'',
                            site_name:'',
                            email:'',
                            address:'',
                            first_phone:'',
                            second_phone:'',
                            whats_app:'',
                            facebook:'',
                            youtube:'',
                            instagram:'',
                        });
                        swal("Success",res.data.message,"success");
                        navigate('/profile',{replace:true})

                    }else
                    {
                        swal("Warning",res.data.error,"warning");

                    }
                setSentTranslation(!sentTranslation)
                }
            );
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addProfileTranslation}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Add Translation</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/profile">Profile</Link></li>
                                        <li><Link to="#">Add translation</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Profile Information</h2>
                                    <div className="formGroup required">
                                        <label htmlFor="translation" className="form-label">Translation</label>
                                        <select
                                            className="form-control"
                                            value={selectedTranslationValue}
                                            data-live-search="true" name="translation" id="translation"
                                            onChange={(e)=>setSelectedValue(e.target.value)}
                                        >
                                            <option value='0'>select translation</option>
                                            {translations.map((translation,index)=>
                                                <option key={index}  value={translation}>{translation}</option>
                                            )}
                                        </select>
                                        {errors.selectedTranslation && <em className="error hint" style={{color:'red'}}>{errors.selectedTranslation}</em>}

                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="site_name" className="form-label">Site name</label>
                                        <input type="site_name"
                                               id="site_name"
                                               name="site_name"
                                               className="form-control"
                                               value={profileData.site_name}
                                               onChange={handleInput}
                                        />
                                        {errors.site_name && <em className="error hint" style={{color:'red'}}>{errors.site_name}</em>}
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email"
                                               id="email"
                                               name="email"
                                               className="form-control"
                                               value={profileData.email}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <input type="text"
                                               id="address"
                                               name="address"
                                               className="form-control"
                                               value={profileData.address}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="first_phone" className="form-label">First phone</label>
                                        <input type="number"
                                               id="first_phone"
                                               name="first_phone"
                                               className="form-control"
                                               value={profileData.first_phone}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="second_phone" className="form-label">Second phone</label>
                                        <input type="number"
                                               id="second_phone"
                                               name="second_phone"
                                               className="form-control"
                                               value={profileData.second_phone}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="whats_app" className="form-label">WhatsApp</label>
                                        <input type="number"
                                               id="whats_app"
                                               name="whats_app"
                                               className="form-control"
                                               value={profileData.whats_app}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="facebook" className="form-label">FaceBook</label>
                                        <input type="text"
                                               id="facebook"
                                               name="facebook"
                                               className="form-control"
                                               value={profileData.facebook}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="instagram" className="form-label">Instagram</label>
                                        <input type="text"
                                               id="instagram"
                                               name="instagram"
                                               className="form-control"
                                               value={profileData.instagram}
                                               onChange={handleInput}
                                        />
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="youtube" className="form-label">YouTube</label>
                                        <input type="text"
                                               id="youtube"
                                               name="youtube"
                                               className="form-control"
                                               value={profileData.youtube}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="logo" className="form-label">Logo</label>
                                        <input type="file"
                                               id="logo"
                                               name="logo"
                                               className="form-control"
                                               onChange={(e) =>setLogo(e.target.files)}

                                        />
                                    </div>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>

    )
}
export const baseURL = `https://api.andraos-co.com/api`;
export const imageUrl = 'https://andraos-co.com/andraosImage/';

export const LOGIN = 'login';
export const REGISTER = 'register';
export const LOGOUT = 'logout';

export const CURRENT_LOCALE = 'current-locale'


// User Api

export const USERS_LIST = 'users-list';
export const ADD_USER = 'add-user';
export const EDIT_USER = 'edit-user';
export const DELETE_USER = 'delete-user';
export const USER_DATA = 'user-data';
export const USER = 'user';


// Brand Api
export const BRANDS = 'brands';
export const ADD_BRAND = 'add-brand';
export const EDIT_BRAND = 'edit-brand';
export const DELETE_BRAND = 'delete-brand';
export const BRAND_DATA = 'brand-data';
export const DELETE_BRAND_IMAGE = 'delete-brand-image';
export const DELETE_BRAND_BANNER_IMAGE = 'delete-brand-banner-image';
export const DELETE_BRAND_BANNER_MOBILE_IMAGE = 'delete-brand-banner-mobile-image';

export const UNUSED_BRAND_TRANSLATION = 'get-unused-brand-translation';
export const BRAND_TRANSLATION_DATA = 'brand-translation-data';
export const CREATE_BRAND_TRANSLATION = 'create-brand_translation';
export const EDIT_BRAND_TRANSLATION = 'edit-brand-translation';


// Category Api

export const CATEGORIES = 'categories';
export const CATEGORIES_TABLE = 'categories-table';
export const ADD_CATEGORY = 'add-category';
export const EDIT_CATEGORY = 'edit-category';
export const DELETE_CATEGORY = 'delete-category';
export const CATEGORY_DATA = 'category-data';
export const DELETE_CATEGORY_IMAGE = 'delete-category-image';
export const DELETE_CATEGORY_BANNER_IMAGE = 'delete-category-banner-image';
export const DELETE_CATEGORY_BANNER_MOBILE_IMAGE = 'delete-category-banner-mobile-image';


export const CATEGORY_TRANSLATION_DATA = 'category-translation-data';
export const CREATE_CATEGORY_TRANSLATION = 'create-category_translation';
export const EDIT_CATEGORY_TRANSLATION = 'edit-category_translation';
export const UNUSED_CATEGORY_TRANSLATION = 'get-unused-translation';


// Pages Api
export const GET_PAGES = 'get-pages';
export const PAGES = 'pages';
export const  ADD_PAGE = 'add-page';
export const  PAGE_DATA = 'page-data';
export const  EDIT_PAGE = 'edit-page';
export const  DELETE_PAGE_IMAGE = 'delete-page-image';
export const  DELETE_PAGE_BANNER_IMAGE = 'delete-page-banner-image';
export const  DELETE_PAGE_BANNER_MOBILE_IMAGE = 'delete-page-banner-mobile-image';
export const  DELETE_PAGE = 'delete-page';


export const  UNUSED_PAGE_TRANSLATION = 'get-unused-page-translation';
export const  CREATE_PAGE_TRANSLATION = 'create-page-translation';
export const  EDIT_PAGE_TRANSLATION = 'edit-page-translation';
export const  PAGE_TRANSLATION_DATA = 'page-translation-data';


// Products Api

export const GET_CATEGORIES  = 'get-categories';
export const BRAND_SELECT_LIST  = 'brands-select-list';

export const PRODUCTS = 'products';
export const ADD_PRODUCT  = 'add-product';
export const PRODUCT_DATA  = 'product-data';
export const EDIT_PRODUCT  = 'edit-product';
export const DELETE_PRODUCT_IMAGE  = 'delete-product-image';
export const DELETE_PRODUCT  = 'delete-product';


export const UNUSED_PRODUCT_TRANSLATION  = 'get-unused-product-translation';
export const CREATE_PRODUCT_TRANSLATION  = 'create-product_translation';
export const PRODUCT_TRANSLATION_DATA  = 'product-translation-data';
export const EDIT_PRODUCT_TRANSLATION  = 'edit-product_translation';

// Profile Api

export const CREATE_PROFILE = 'create-profile';
export const DELETE_LOGO_IMAGE = 'delete-logo-image';

export const UNUSED_PROFILE_TRANSLATION = 'get-unused-profile-translation';
export const CREATE_PROFILE_TRANSLATION = 'create-profile-translation';
export const PROFILE_TRANSLATION_DATA = 'profile-translation-data';
export const EDIT_PROFILE_TRANSLATION = 'edit-profile-translation';
export const PROFILE_DATA = 'profile-data';
export const EXPORT_DEFAULT_LANGUAGE = 'change-default-language';
export default function Validation (values  , selectedBrandValue , selectedCategoryValue , selectedTranslationValue)
{
    const errors = {};

    // Category Validation
    if(values.category_name==="")
    {
        errors.category_name = "Category name is required";
    }else{
        errors.category_name = "";
    }


    if(values.category_url==="")
    {
        errors.category_url = "Category url is required"
    }else{
        errors.category_url ="";
    }

    if(values.meta_title==="")
    {
        errors.meta_title = "Meta title is required"
    }else{
        errors.meta_title = "";
    }


    // Brand Validation

    if(values.brand_name==="")
    {
        errors.brand_name = "Brand name is required";
    }else{
        errors.brand_name = "";
    }

    if(values.brand_url==="")
    {
        errors.brand_url = "Brand url is required"
    }else{
        errors.brand_url ="";
    }



    // Products Validation

    if(values.product_name ==="")
    {
        errors.product_name = "Product name is required"
    }else {
        errors.product_name = "";
    }


    if(values.product_url==="")
    {
        errors.product_url = "Product url is required"
    }else{
        errors.product_url ="";
    }


    if(selectedBrandValue===0)
    {
        errors.brand_id = "Brand is required"
    }else{
        errors.brand_id = "";
    }

    if(selectedCategoryValue===0)
    {
        errors.category_id = "Category is required"
    }else{
        errors.category_id = "";
    }



    // Auth And User Validation
    if(values.email==="") {
        errors.email = "Email is required"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {

        errors.email = "Invalid email"
    }else{
        errors.email = "";
    }

    if(values?.password==="")
    {
        errors.password = "Password is required"

    }

    else if(values.password?.length <8)
    {
        errors.password = 'Please enter 8 characters at least'
    }

    if(values.confirm_password !== values.password)
    {
        errors.confirm_password = "Passwords don't match";
    }else{
        errors.confirm_password = "";
    }

    if(values?.unauthorized ==='unauthorized')
    {
        errors.unauthorized = 'Email or password is wrong'
    }else{
        errors.unauthorized = '';
    }

    if(values.user_name ==="")
    {
        errors.user_name = "Username is required"
    }else{
        errors.user_name = ""
    }

    if(selectedTranslationValue === 0 )
    {
        errors.selectedTranslation = "Language is required"
    }else{
        errors.selectedTranslation = ""
    }

    // Profile Validation
    if(values.site_name==="")
    {
        errors.site_name = "Site name is required"
    }else{
        errors.site_name = "";
    }

    // Page Validation

    if(values.title==="")
    {
        errors.title = "Page title is required";
    }else{
        errors.title = "";
    }

    if(values.page_url==="")
    {
        errors.page_url = "Page url is required";
    }else{
        errors.page_url = "";

    }


    return errors;
}
import {Link, useNavigate} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {ADD_BRAND, baseURL, CURRENT_LOCALE} from "../Apis/Api";

export default function AddBrand()
{

    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [brandImage, setBrandImage] = useState([]);
    const [bannerImage, setBannerImage] = useState([]);
    const [mobileBannerImage, setMobileBannerImage] = useState([]);
    const [locale , setLocale] = useState('');
    const navigate = useNavigate();
    const brandNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');
    const [allCheckBox , setCheckBoxes] = useState({
        in_home:false,
        in_footer:false,
        sort_order:false,
        status:false
    });

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${CURRENT_LOCALE}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setLocale(res.data.locale);
            });
        };
        getData();
    }, []);

    const [brandData , setBrandData] = useState({
        brand_name:"",
        brand_content:"",
        brand_url:"",
        brand_image:"",
        banner_image:"",
        mobile_banner_image:"",
        meta_title:"",
        meta_description:"",

    });
    const brandImageRef = useRef();
    const bannerRef = useRef();
    const mobileBannerRef = useRef();

    const fillUrl =  () => {

        let name = brandNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="" && locale!=="") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setBrandData({...brandData, ['brand_url']: NSlocale + '/' + NSname});
        }else{
            metaUrl.current.value = "";
            setBrandData({...brandData, ['brand_url']: "" });
        }
    }


    const fillMetaTitle = ()=> {
        let name = brandNameRef.current.value;
        if(name !=="")
        {
            let siteName = 'andraos';
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            brandData.meta_title = NSname + ' | ' + siteName;
        }else{
            metaTitle.current.value = "";
            brandData.meta_title = "";
        }
    }

    async function handleCheckBox (e) {
        e.persist();
        setCheckBoxes({
            ...allCheckBox , [e.target.name]:e.target.checked
        });
    };

    const handleInput = (e) => {
        e.persist();
        setBrandData({...brandData, [e.target.name]:e.target.value });
    }
   async function addBrand (e) {
        e.preventDefault();
        // Send Form Data
        let accept = true;
        setErrors(Validation(brandData));

        if(brandData.brand_name==="" || brandData.brand_url==="" || brandData.meta_title==="") {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('brand_name', brandData.brand_name || "");
            formData.append('brand_content', brandData.brand_content || "");
            formData.append('brand_url', brandData.brand_url || "");
            formData.append('meta_title', brandData.meta_title || "");
            formData.append('meta_description', brandData.meta_description || "");
            formData.append('brand_image',brandImage[0]);
            formData.append('banner_image',bannerImage[0]);
            formData.append('mobile_banner_image',mobileBannerImage[0]);
            formData.append('in_home', allCheckBox.in_home ? '1':'0');
            formData.append('in_footer', allCheckBox.in_footer ? '1':'0');
            formData.append('status', allCheckBox.status ? '1':'0');
            formData.append('sort_order', allCheckBox.sort_order ? '1':'0');

          await  axios.post(`${baseURL}/${ADD_BRAND}`,formData,{
              headers:{
                  Accept: 'application/json',
                  Authorization: `Bearer ${token}`
              }
              }
            ).then(res=>{
                if(res.data.status === 200) {
                    swal("Success",res.data.message,"success");

                    //Reset Data After Submit

                    setBrandData({
                        brand_name:"",
                        brand_content:"",
                        brand_url:"",
                        brand_image:"",
                        banner_image:"",
                        mobile_banner_image:"",
                        meta_title:"",
                        meta_description:"",
                    });
                    setCheckBoxes({
                        in_home:false,
                        in_footer:false,
                        sort_order:false,
                        status:false
                    });

                    brandImageRef.current.value = "";
                    bannerRef.current.value = "";
                    mobileBannerRef.current.value = "";
                    navigate('/brands-list' , {replace:true});
                }else{
                    swal("Warning",res.data.error,"warning");
                }
          });

        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addBrand}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>New Brand</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/brands-list">Brands table</Link></li>
                                        <li><Link to="#">New brand</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <input hidden  readOnly   ref={localeRef} value={locale} onChange={()=>console.log('')}/>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Brand Information</h2>
                                    <div className="formGroup required">
                                        <label htmlFor="brand_name" className="form-label">Brand name</label>
                                        <input type="text"
                                               ref={brandNameRef}
                                               id="brand_name"
                                               name="brand_name"
                                               className="form-control"
                                               value={brandData.brand_name}
                                               onChange={handleInput}
                                        />
                                        {errors.brand_name && <em className="error hint" style={{color:'red'}}>{errors.brand_name}</em>}
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="brand_content" className="form-label">Brand Content</label>
                                        <input type="text"
                                               id="brand_content"
                                               name="brand_content"
                                               className="form-control"
                                               value={brandData.brand_content}
                                               onChange={handleInput}
                                        />
                                    </div>
                                    <div className="formGroup quarter required">
                                        <label htmlFor="brand_url" className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="brand_url"
                                            id="brand_url"
                                            value={brandData.brand_url}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.brand_url && <em className="error hint" style={{color:'red'}}>{errors.brand_url}</em>}
                                    </div>

                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={brandData.meta_title}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}

                                    </div>

                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={brandData.meta_description}
                                            onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="brand_image" className="form-label">Image 500 X 500</label>
                                        <input type="file" name="brand_image"
                                               ref={brandImageRef}
                                               onChange={(e) =>setBrandImage(e.target.files)}
                                               className="form-control"/>

                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="brand_banner" className="form-label">Banner image 1900 X 500</label>
                                        <input type="file" name="brand_banner" onChange={(e)=>setBannerImage(e.target.files)}
                                               ref={bannerRef}
                                               className="form-control"/>

                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="mobile_banner_image" className="form-label">Mobile banner 767 X 300</label>
                                        <input type="file" name="mobile_banner_image" onChange={(e)=>setMobileBannerImage(e.target.files)}
                                               ref={mobileBannerRef}
                                               className="form-control"/>

                                    </div>

                                    <div className="formGroup wide checkGroup">
                                <span>
                                    <input  className="form-check-input" name="status"
                                            checked={allCheckBox.status}
                                            onChange={handleCheckBox}
                                            id="status" type="checkbox"/>
                                    <label htmlFor="status" className="form-check-label">Status</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="in_home"
                                            checked={allCheckBox.in_home}
                                            onChange={handleCheckBox}
                                            id="in_home" type="checkbox"/>
                                    <label htmlFor="in_home" className="form-check-label">In home</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="in_footer"
                                            checked={allCheckBox.in_footer}
                                            onChange={handleCheckBox}
                                            id="in_footer" type="checkbox"/>
                                    <label htmlFor="in_footer" className="form-check-label">In footer</label>
                                </span>

                                        <span>
                                    <input  className="form-check-input" name="sort_order"
                                            checked={allCheckBox.sort_order}
                                            onChange={handleCheckBox}
                                            id="sort_order" type="checkbox"/>
                                    <label htmlFor="sort_order" className="form-check-label">Sort order</label>
                                </span>

                                    </div>


                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                                <div className="functionBar">
                                    <div className="col-auto fanctions">
                                        <Link to="" className="fancItem alert-success">
                                            <i className="bi bi-card-list"></i><span>customers</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-warning">
                                            <i className="bi bi-node-plus"></i><span>add_supplier</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-primary">
                                            <i className="bi bi-node-plus"></i><span>create_account</span>
                                        </Link>
                                    </div>
                                    <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
import {Link} from 'react-router-dom'
import axios from "axios";
import Header from "../Layouts/Header";
import Cookies from "universal-cookie";
import {useState} from "react";
import {baseURL, CREATE_PROFILE} from "../Apis/Api";
export default function AddProfile()
{

    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [profileData , setProfileData] = useState({
       'title':'',
       'site_name':'',
       'email':'',
       'address':'',
       'first_phone':'',
       'second_phone':'',
       'whats_app':'',
       'facebook':'',
       'youtube':'',
       'instagram':'',
    });

    const [logo , setLogo] = useState([]);

    const handleInput = (e) => {
        e.persist();
        setProfileData({...profileData, [e.target.name]:e.target.value });

    }

    const addProfile = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', profileData.title);
        formData.append('site_name', profileData.site_name);
        formData.append('email', profileData.email);
        formData.append('address', profileData.address);
        formData.append('first_phone', profileData.first_phone);
        formData.append('second_phone', profileData.second_phone);
        formData.append('whats_app', profileData.whats_app);
        formData.append('facebook', profileData.facebook);
        formData.append('youtube', profileData.youtube);
        formData.append('instagram', profileData.instagram);
        formData.append('logo', logo[0]);
        axios.post(`${baseURL}/${CREATE_PROFILE}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(()=>console.log(''))
        }


    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addProfile}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Profile</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/profile">Profile table</Link></li>
                                        <li><Link to="#">New Profile</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Profile Information</h2>
                                    <div className="formGroup">
                                        <label htmlFor="title" className="form-label">Title</label>
                                        <input type="text"
                                               id="title"
                                               name="title"
                                               className="form-control"
                                               value={profileData.title}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="site_name" className="form-label">Site name</label>
                                        <input type="site_name"
                                               id="site_name"
                                               name="site_name"
                                               className="form-control"
                                               value={profileData.site_name}
                                               onChange={handleInput}
                                        />
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email"
                                               id="email"
                                               name="email"
                                               className="form-control"
                                               value={profileData.email}
                                               onChange={handleInput}
                                        />
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <input type="address"
                                               id="address"
                                               name="address"
                                               className="form-control"
                                               value={profileData.address}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="first_phone" className="form-label">First phone</label>
                                        <input type="text"
                                               id="first_phone"
                                               name="first_phone"
                                               className="form-control"
                                               value={profileData.first_phone}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="second_phone" className="form-label">Second phone</label>
                                        <input type="text"
                                               id="second_phone"
                                               name="second_phone"
                                               className="form-control"
                                               value={profileData.second_phone}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="whats_app" className="form-label">WhatsApp</label>
                                        <input type="text"
                                               id="whats_app"
                                               name="whats_app"
                                               className="form-control"
                                               value={profileData.whats_app}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="facebook" className="form-label">FaceBook</label>
                                        <input type="text"
                                               id="facebook"
                                               name="facebook"
                                               className="form-control"
                                               value={profileData.facebook}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="instagram" className="form-label">Instagram</label>
                                        <input type="text"
                                               id="instagram"
                                               name="instagram"
                                               className="form-control"
                                               value={profileData.instagram}
                                               onChange={handleInput}
                                        />
                                    </div>


                                    <div className="formGroup">
                                        <label htmlFor="youtube" className="form-label">YouTube</label>
                                        <input type="text"
                                               id="youtube"
                                               name="youtube"
                                               className="form-control"
                                               value={profileData.youtube}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="logo" className="form-label">Logo</label>
                                        <input type="file"
                                               id="logo"
                                               name="logo"
                                               className="form-control"
                                               onChange={(e) =>setLogo(e.target.files)}

                                        />
                                    </div>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>

    )
}
import {Link, useNavigate} from 'react-router-dom'
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {
    baseURL,
    BRAND_DATA,
    DELETE_BRAND_BANNER_IMAGE,
    DELETE_BRAND_BANNER_MOBILE_IMAGE,
    DELETE_BRAND_IMAGE,
    EDIT_BRAND, imageUrl
} from "../Apis/Api";

export default function EditBrand()
{
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [brandSent, setBrandSent] = useState(false);
    const [errors , setErrors] = useState({});
    const [brandImage, setBrandImage] = useState([]);
    const [bannerImage, setBannerImage] = useState([]);
    const [mobileBannerImage, setMobileBannerImage] = useState([]);
    const navigate = useNavigate();
    const [brandData , setBrandData] = useState({
        brand_name:"",
        brand_content:"",
        brand_url:"",
        meta_title:"",
        meta_description:"",
        brand_image:"",
        brand_banner:"",
        brand_mobile_banner:"",
        status:0,
        in_footer:0,
        in_home:0,
        sort_order:0,
        locale:""
    });

    const brandNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const [status , setStatusCheck] = useState(brandData.status === 1);
    const [inFooter , setInFooterCheck] = useState(brandData.in_footer === 1);
    const [inHome , setInHomeCheck] = useState(brandData.in_home === 1);
    const [sortOrder , setSortOrderCheck] = useState(brandData.sort_order === 1);

    const brandImageRef = useRef('');
    const brandBannerImageRef = useRef('');
    const brandMobileBannerImageRef = useRef('');

    const currentImage = useRef(null);
    const currentBannerImage = useRef(null);
    const currentMobileBanner = useRef(null);

    const fillUrl =  () => {

        let name = brandNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="" && locale!=="0") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setBrandData({...brandData, ['brand_url']: NSlocale + '/' + NSname});
        }else{
            metaUrl.current.value = "";
            setBrandData({...brandData, ['brand_url']: "" });
        }

    }
    const fillMetaTitle = ()=> {
        let name = brandNameRef.current.value;
        if(name !=="")
        {
            let siteName = 'androas';
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            setBrandData({...brandData, ['meta_title']: NSname + ' | ' + siteName});

        }else{
            metaTitle.current.value = "";
            setBrandData({...brandData, ['meta_title']: "" });
        }
    }

    const setStatusFunction =(e)=>{
        e.persist();
        setStatusCheck(!status);
    }

    const setInHomeFunction = (e)=>{
        e.persist();
        setInHomeCheck(!inHome);
    }

    const setInFooterFunction = (e)=>{
        e.persist();
        setInFooterCheck(!inFooter);
    }

    const setSortOrderFunction = (e)=>{
        e.persist();
        setSortOrderCheck(!sortOrder);
    }

    const handleInput = (e) => {
        e.persist();
        setBrandData({...brandData, [e.target.name]:e.target.value });

    }
    const id = Number(window.location.pathname.replace('/edit-brand/',""));

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${BRAND_DATA}/${id}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setBrandData(res.data.brandData)
                setStatusCheck(res.data.brandData.status)
                setInFooterCheck(res.data.brandData.in_footer)
                setInHomeCheck(res.data.brandData.in_home)
                setSortOrderCheck(res.data.brandData.sort_order)
            });
        };
        getData();
    }, [brandSent]);

    const deleteBrandImage=() => {
        axios.post(`${baseURL}/${DELETE_BRAND_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            setBrandSent(!brandSent);
            swal("Success",res.data.message,"success");

        })
    }

    const deleteBannerImage=() => {
        axios.post(`${baseURL}/${DELETE_BRAND_BANNER_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            setBrandSent(!brandSent);
            swal("Success",res.data.message,"success");

        })
    }

    const deleteMobileBannerImage=() => {
        axios.post(`${baseURL}/${DELETE_BRAND_BANNER_MOBILE_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            setBrandSent(!brandSent);
            swal("Success",res.data.message,"success");

        })
    }

    const updateBrand = (e) => {
        e.preventDefault();
        let accept = true;
        setErrors(Validation(brandData));
        if(brandData.brand_name==="" || brandData.brand_url==="" || brandData.meta_title==="") {

            accept = false;
        }else{
            accept = true;
        }

        if(accept===true)
        {
            // Send Form Data
            const formData = new FormData();
            formData.append('brand_name', brandData.brand_name);
            formData.append('brand_content', brandData.brand_content  || "");
            formData.append('brand_url', brandData.brand_url);
            formData.append('meta_title', brandData.meta_title);
            formData.append('meta_description', brandData.meta_description  || "");
            formData.append('brand_image',brandImage[0]);
            formData.append('current_brand_image',currentImage.current.value);
            formData.append('brand_banner',bannerImage[0]);
            formData.append('current_banner_image',currentBannerImage.current.value);
            formData.append('brand_mobile_banner',mobileBannerImage[0]);
            formData.append('current_mobile_banner_image',currentMobileBanner.current.value);
            formData.append('in_home', inHome ? '1':'0');
            formData.append('in_footer', inFooter ? '1':'0');
            formData.append('status', status ? '1':'0');
            formData.append('sort_order', sortOrder ? '1':'0');
            formData.append('locale', brandData.locale);
            axios.post(`${baseURL}/${EDIT_BRAND}/${id}`,formData,{
                    headers:{
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                },
            ).then(res=>{
                if(res.data.status === 200)
                {
                    brandImageRef.current.value = "";
                    brandBannerImageRef.current.value = "";
                    brandMobileBannerImageRef.current.value = "";
                    swal("Success",res.data.message,"success");
                    navigate('/brands-list' , {replace:true});

                }
                else
                {
                    swal("Warning",res.data.error,"warning");

                }
            });
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={updateBrand}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Edit Brand</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/brands-list">Brands table</Link></li>
                                        <li><Link to="#">Edit brand</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Edit brand</h2>
                                    <div className="formGroup required">
                                        <label htmlFor="locale" className="form-label">Language</label>
                                        <input type="text"
                                               ref={localeRef}
                                               id="locale"
                                               name="locale"
                                               readOnly
                                               className="form-control"
                                               value={brandData.locale || ""}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="brand_name" className="form-label">Brand name</label>
                                        <input type="text"
                                               ref={brandNameRef}
                                               id="brand_name"
                                               name="brand_name"
                                               className="form-control"
                                               value={brandData.brand_name  || ""}
                                               onChange={handleInput}
                                        />
                                        {errors.brand_name && <em className="error hint" style={{color:'red'}}>{errors.brand_name}</em>}
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="brand_content" className="form-label">Brand content</label>
                                        <input type="text"
                                               id="brand_content"
                                               name="brand_content"
                                               className="form-control"
                                               value={brandData.brand_content  || ""}
                                               onChange={handleInput}
                                        />
                                    </div>
                                    <div className="formGroup quarter required">
                                        <label htmlFor="brand_url" className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="brand_url"
                                            id="brand_url"
                                            value={brandData.brand_url  || ""}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.brand_url && <em className="error hint" style={{color:'red'}}>{errors.brand_url}</em>}
                                    </div>
                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={brandData.meta_title  || ""}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                    </div>
                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={brandData.meta_description  || ""}
                                            onChange={handleInput}
                                        />
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="brand_image" className="form-label">Image 500 X 500</label>
                                        <input type="file" name="brand_image"  ref={brandImageRef}
                                               onChange={(e) =>setBrandImage(e.target.files)}
                                               className="form-control"/>
                                        <input type="hidden"
                                               onChange = {(e)=>console.log('')}
                                               ref={currentImage}
                                               value={brandData.brand_image || ""}/>
                                        {brandData.brand_image === null || brandData.brand_image === "" ? "" :
                                        <div>
                                            <img style={{width: '100px'}} src={`${imageUrl}brands/${brandData.brand_image}`}/>
                                            <a type='button' onClick={deleteBrandImage}>delete</a>
                                        </div>
                                        }
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="brand_banner" className="form-label">Banner image 1900 X 500</label>
                                        <input type="file" name="brand_banner" onChange={(e)=>setBannerImage(e.target.files)}
                                               ref={brandBannerImageRef}
                                               className="form-control"/>
                                        <input type="hidden"
                                               onChange = {(e)=>console.log('')}
                                               ref={currentBannerImage}
                                               value={brandData.brand_banner || ""}/>
                                        {brandData.brand_banner=== null || brandData.brand_banner==="" ? "" :
                                        <div>
                                            <img  style={{width:'100px'}}  src={`${imageUrl}brands/${brandData.brand_banner}`}/>
                                            <a type='button' onClick={deleteBannerImage}>delete</a>
                                        </div>
                                        }
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="brand_mobile_banner" className="form-label">Mobile banner 767 X 300</label>
                                        <input  type="file"
                                                name="brand_mobile_banner" onChange={(e)=>setMobileBannerImage(e.target.files)}
                                               ref={brandMobileBannerImageRef}
                                               className="form-control"/>
                                        <input
                                            type="hidden"
                                            onChange = {(e)=>console.log('')}
                                            ref={currentMobileBanner}
                                            value={brandData.brand_mobile_banner || "" }/>
                                           {brandData.brand_mobile_banner ===null || brandData.brand_mobile_banner === "" ? "":
                                            <div>
                                            <img  style={{width:'100px'}}  src={`${imageUrl}brands/${brandData.brand_mobile_banner}`}/>
                                                <a type='button' onClick={deleteMobileBannerImage}>delete</a>
                                            </div>
                                        }
                                    </div>
                                    <div className="formGroup wide checkGroup">
                                <span>
                                  <input
                                      type="checkbox"
                                      name="status"
                                      onChange={setStatusFunction}
                                      checked={status}
                                      className="form-check-input"
                                  />
                                    <label htmlFor="status" className="form-check-label">Status</label>
                                </span>
                                        <span>
                                     <input
                                         type="checkbox"
                                         name="in_home"
                                         onChange={setInHomeFunction}
                                         className="form-check-input"
                                         checked={inHome}

                                     />
                                    <label htmlFor="in_home" className="form-check-label">in_home</label>
                                </span>
                                        <span>
                                    <input
                                        type="checkbox"
                                        name="in_footer"
                                        onChange={setInFooterFunction}
                                        className="form-check-input"
                                        checked={inFooter}
                                    />
                                    <label htmlFor="in_footer" className="form-check-label">in_footer</label>
                                </span>
                                        <span>
                                    <input
                                        type="checkbox"
                                        name="sort_order"
                                        onChange={setSortOrderFunction}
                                        className="form-check-input"
                                        checked={sortOrder}

                                    />
                                    <label htmlFor="sort_order" className="form-check-label">Sort order</label>
                                </span>
                                    </div>
                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                                <div className="functionBar">
                                    <div className="col-auto fanctions">
                                        <Link to="" className="fancItem alert-success">
                                            <i className="bi bi-card-list"></i><span>customers</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-warning">
                                            <i className="bi bi-node-plus"></i><span>add_supplier</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-primary">
                                            <i className="bi bi-node-plus"></i><span>create_account</span>
                                        </Link>
                                    </div>
                                    <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
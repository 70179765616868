import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import swal from "sweetalert";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {baseURL, CATEGORIES_TABLE, DELETE_CATEGORY} from "../Apis/Api";

export default function CategoriesList ()
{

    const [categoryList, setCategoryList] = useState([]);
    const [deletedCategory , setDeletedCategory] = useState(false);
    const cookies = new Cookies();
    const token = cookies.get('Bearer');

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${CATEGORIES_TABLE}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setCategoryList(res.data[0])
            });
        };
        getData();
    }, [deletedCategory]);


   async function deleteCategory(id)
    {
        await axios.get(`${baseURL}/${DELETE_CATEGORY}/${id}`,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            if(res.data.status===200)
            {
                swal("Success",res.data.message,"success");
            }else{
                swal("Warning",res.data.error,"warning");
            }

            setDeletedCategory(!deletedCategory);
        });
    }

    return (
      <div>
          <Header/>
          <main id="homeMain" tabIndex="-1">
              <div className="container-fluid">
                  <div className="containerWrap">
                      <div className="row bodyHeader">
                          <div className="col-auto">
                              <h1>Categories Table</h1>
                              <ul className="breadcrumb">
                                  <li><Link to="/dashboard">Dashboard</Link></li>
                                  <li><Link to="">Categories table</Link></li>
                              </ul>
                          </div>
                      </div>
                      <div className="row">
                          <div className="mainBody">
                              <table className="table table-striped dataTable no-footer" id="samatek-datatable" aria-describedby="samatek-datatable_info"
                                     data-table-max-column="6">
                                  <thead>
                                  <tr>
                                      <th>Id</th>
                                      <th>Category name</th>
                                      <th>Main category</th>
                                      <th>Meta title</th>
                                      <th>Category url</th>
                                      <th>Languages</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {categoryList.map((category,index)=>
                                          <tr key={index}>
                                              <td>{category.id}</td>
                                              <td>{category.category_name}</td>
                                              <td> {category.categories ? category.categories.category_name :'main category'}</td>
                                              <td>{category.meta_title}</td>
                                              <td>{category.category_url}</td>
                                              <td>
                                                  {category.translations.map((translation,index)=>
                                                      <Link to={`/edit-category-translation/${translation.id}/${category.id}`} key={index}>   {translation.locale} </Link>
                                                  )}
                                              </td>
                                              <td>{category.status}</td>
                                              <td>
                                <span className="function">
                                <Link className="alert-success" title="edit"  to={`/edit-category/${category.id}`}>
                                <i className="bi bi-pencil-square"></i>
                                </Link>
                                     <Link className="alert-success" title="add translation"  to={`/add-category-translation/${category.id}`}>
                                <i className="bi bi-node-plus"></i>
                                </Link>
                                <button onClick={()=>deleteCategory(category.id)} className="alert-danger" type="submit" title="delete"><i className="bi bi-trash3"></i></button>
                                </span>
                                              </td>
                                          </tr>
                                  )}
                                  </tbody>
                              </table>
                          </div>
                          <div className="functionBar">
                              <div className="col-auto fanctions">
                                  <Link to="/add-category" className="fancItem alert-success" title="add">
                                      <i className="bi bi-node-plus"></i><span>New category</span>
                                  </Link>
                              </div>
                              <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                          </div>
                      </div>
                  </div>
              </div>
          </main>
          <Footer/>
      </div>
    )
}
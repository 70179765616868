import {Link, useNavigate} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {ADD_CATEGORY, baseURL, CATEGORIES, CURRENT_LOCALE} from "../Apis/Api";

export default function AddCategory()
{

    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [selectedValue , setSelectedValue] = useState(0);
    const [categoryImage, setCategoryImage] = useState([]);
    const [bannerImage, setBannerImage] = useState([]);
    const [mobileBannerImage, setMobileBannerImage] = useState([]);
    const [locale , setLocale] = useState('');
    const categoryRef = useRef();
    const navigate = useNavigate();
    const categoryNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const [allCheckBox , setCheckBoxes] = useState({
        in_home:false,
        in_footer:false,
        sort_order:false,
        status:false
    });
    const categoryImageRef = useRef();
    const bannerRef = useRef();
    const mobileBannerRef = useRef();
    const [categoryData , setCategoryData] = useState({
        category_name:"",
        category_content:"",
        category_url:"",
        meta_title:"",
        meta_description:"",
        category_image:"",
        category_banner:"",
        mobile_banner_image:"",
    });

    async function handleCheckBox (e) {
        e.persist();
        setCheckBoxes({
           ...allCheckBox , [e.target.name]:e.target.checked
        });
    };

    const handleInput = (e) => {
        e.persist();
        setCategoryData({...categoryData, [e.target.name]:e.target.value });

    }

    useEffect(() => {
        const getData = async () => {

            await axios.get(`${baseURL}/${CATEGORIES}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setCategoryList(res.data[0])
            });

            await axios.get(`${baseURL}/${CURRENT_LOCALE}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setLocale(res.data.locale);
            });
        };
        getData();
    }, []);



    const fillUrl =  () => {

        let name = categoryNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setCategoryData({...categoryData, ['category_url']: NSlocale + '/' + NSname });
        }else{
            metaUrl.current.value = "";
            setCategoryData({...categoryData, ['category_url']:"" });
        }
    }

    const fillMetaTitle = ()=> {
        let name = categoryNameRef.current.value;
        let siteName = 'androas';
        if(name!=="")
        {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            setCategoryData({...categoryData, ['meta_title']:NSname + ' | ' + siteName });
        }else{
            metaTitle.current.value = "";
            setCategoryData({...categoryData, ['meta_title']: "" });
        }
    }

    const addCategory = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
          setErrors(Validation(categoryData));

        if(categoryData.category_name==="" || categoryData.category_url==="" || categoryData.meta_title==="") {

         accept = false;

        }else{
           accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('parent_id', selectedValue);
            formData.append('category_name', categoryData.category_name || "");
            formData.append('category_content', categoryData.category_content ? categoryData.category_content :"");
            formData.append('category_url', categoryData.category_url || "");
            formData.append('meta_title', categoryData.meta_title || "");
            formData.append('meta_description', categoryData.meta_description ? categoryData.meta_description :"");
            formData.append('category_image',categoryImage[0]);
            formData.append('category_banner',bannerImage[0]);
            formData.append('mobile_banner_image',mobileBannerImage[0]);
            formData.append('in_home', allCheckBox.in_home ? '1':'0');
            formData.append('in_footer', allCheckBox.in_footer ? '1':'0');
            formData.append('status', allCheckBox.status ? '1':'0');
            formData.append('sort_order', allCheckBox.sort_order ? '1':'0');

            axios.post(`${baseURL}/${ADD_CATEGORY}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                if(res.data.status === 200) {
                    swal("Success",res.data.message,"success");

                    navigate('/categories-list' , {replace:true});
                    // setCategorySent(!categorySent);
                }
                else{
                    swal("Warning",res.data.error,"warning");

                }
                });
        }
    }

    return (
       <div>
           <Header/>
           <main id="homeMain" tabIndex="-1">
               <div className="container-fluid">
                   <div className="containerWrap">
                       <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addCategory}>
                           <div className="row bodyHeader">
                               <div className="col-auto">
                                   <h1>New category</h1>
                                   <ul className="breadcrumb">
                                       <li><Link to="/dashboard">Dashboard</Link></li>
                                       <li><Link to="/categories-list">Categories table</Link></li>
                                       <li><Link to="#">New category</Link></li>
                                   </ul>
                               </div>
                           </div>
                           <div className="row">
                               <div className="mainBody formWrap">
                                   <h2>Category Information</h2>
                                   <input hidden  readOnly   ref={localeRef} value={locale} onChange={()=>console.log('')}/>
                                   <div className="formGroup">
                                       <label>Select category</label>
                                       <select
                                           className="form-control"
                                           data-live-search="true" name="main_category" id="main_category" ref={categoryRef}
                                           onChange={(e)=>setSelectedValue(e.target.value)}>
                                           <option value='0' defaultValue>select category</option>
                                           {categoryList.map((category,index)=>
                                               <option key={index} value={category.id}>{category.category_name}</option>
                                           )}
                                       </select>
                                   </div>

                                   <div className="formGroup required">
                                       <label htmlFor="category_name" className="form-label">Category name</label>
                                       <input type="text"
                                              ref={categoryNameRef}
                                              id="category_name"
                                              name="category_name"
                                              className="form-control"
                                              value={categoryData.category_name}
                                              onChange={handleInput}
                                       />
                                       {errors.category_name && <em className="error hint" style={{color:'red'}}>{errors.category_name}</em>}
                                   </div>

                                   <div className="formGroup">
                                       <label htmlFor="category_content" className="form-label">Category Content</label>
                                       <input type="text"
                                              id="category_content"
                                              name="category_content"
                                              className="form-control"
                                              value={categoryData.category_content}
                                              onChange={handleInput}
                                       />

                                   </div>

                                   <div className="formGroup quarter required">
                                       <label htmlFor="category_url" className="form-label">Url</label>
                                       <input
                                           ref={metaUrl}
                                           className="form-control"
                                           name="category_url"
                                           id="category_url"
                                           value={categoryData.category_url}
                                           onChange={handleInput}
                                       />
                                       <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                       {errors.category_url && <em className="error hint" style={{color:'red'}}>{errors.category_url}</em>}
                                   </div>


                                   <div className="formGroup quarter required">
                                       <label htmlFor="meta_title" className="form-label">Meta title</label>
                                       <input
                                           ref={metaTitle}
                                           className="form-control"
                                           name="meta_title"
                                           id="meta_title"
                                           value={categoryData.meta_title}
                                           onChange={handleInput}
                                       />
                                       <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                       {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                   </div>

                                   <div className="formGroup quarter">
                                       <label htmlFor="meta_description" className="form-label">Meta description</label>
                                       <input
                                           className="form-control"
                                           name="meta_description"
                                           id="meta_description"
                                           value={categoryData.meta_description}
                                           onChange={handleInput}
                                       />
                                   </div>

                                   <div className="formGroup">
                                       <label htmlFor="category_image" className="form-label">Image 500 X 500</label>
                                       <input type="file" name="category_image"
                                              ref={categoryImageRef}
                                              onChange={(e) =>setCategoryImage(e.target.files)}
                                              className="form-control"/>

                                   </div>

                                   <div className="formGroup">
                                       <label htmlFor="category_banner" className="form-label">Banner image 1900 X 500</label>
                                       <input type="file" name="category_banner"
                                              ref={bannerRef}
                                              onChange={(e)=>setBannerImage(e.target.files)}
                                              className="form-control"/>

                                   </div>

                                   <div className="formGroup">
                                       <label htmlFor="mobile_banner_image" className="form-label">Mobile banner 767 X 300</label>
                                       <input type="file" name="mobile_banner_image"
                                              ref={mobileBannerRef}
                                              onChange={(e)=>setMobileBannerImage(e.target.files)}
                                              className="form-control"/>

                                   </div>

                                   <div className="formGroup wide checkGroup">
                                <span>
                                    <input  className="form-check-input" name="status"
                                            checked={allCheckBox.status}
                                            onChange={handleCheckBox}
                                            id="status" type="checkbox"/>
                                    <label htmlFor="status" className="form-check-label">Status</label>
                                </span>

                                       <span>
                                    <input  className="form-check-input" name="in_home"
                                            checked={allCheckBox.in_home}
                                            onChange={handleCheckBox}
                                            id="in_home" type="checkbox"/>
                                    <label htmlFor="in_home" className="form-check-label">In home</label>
                                </span>

                                       <span>
                                    <input  className="form-check-input" name="in_footer"
                                            checked={allCheckBox.in_footer}
                                            onChange={handleCheckBox}
                                            id="in_footer" type="checkbox"/>
                                    <label htmlFor="in_footer" className="form-check-label">In footer</label>
                                </span>

                                       <span>
                                    <input  className="form-check-input" name="sort_order"
                                            checked={allCheckBox.sort_order}
                                            onChange={handleCheckBox}
                                            id="sort_order" type="checkbox"/>
                                    <label htmlFor="sort_order" className="form-check-label">Sort order</label>
                                </span>

                                   </div>


                                   <hr/>
                                   <div className="formGroup wide">
                                       <button type="submit" className="bttn2">save</button>
                                   </div>
                               </div>
                               <div className="functionBar">
                                   <div className="col-auto fanctions">
                                       <Link to="" className="fancItem alert-success">
                                           <i className="bi bi-card-list"></i><span>customers</span>
                                       </Link>
                                       <Link to="" className="fancItem alert-warning">
                                           <i className="bi bi-node-plus"></i><span>add_supplier</span>
                                       </Link>
                                       <Link to="" className="fancItem alert-primary">
                                           <i className="bi bi-node-plus"></i><span>create_account</span>
                                       </Link>
                                   </div>
                                   <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                               </div>
                           </div>
                       </form>
                   </div>
               </div>

           </main>
           <Footer/>
       </div>
    )
}
import {Outlet , Navigate , useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import {useEffect, useState} from "react";
import axios from "axios";
import LoadingPage from "./LoadingPage";
import {baseURL, USER} from "../Apis/Api";

export default function RequiredAuth()
{
    const [user,setUser] = useState([]);
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const Navigate = useNavigate();

    useEffect(()=>{
        axios.get(`${baseURL}/${USER}`,{
            headers:{
                Accept: 'application/json',
                Authorization:'Bearer ' + token
            }
        }).then((data)=>{
            setUser(data.data)
        }).catch(()=>{
            Navigate('/backoffice', {replace:true});
        })
    },[])

    return token ? (
        user.length===0  ? (
     <LoadingPage/> ): (
         <Outlet/>
    )
    ): <Navigate to='/backoffice' replace={true}/>



}
import {Link, useNavigate} from 'react-router-dom'
import { useEffect, useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {
    baseURL,
    BRAND_SELECT_LIST, CATEGORIES_TABLE,
    DELETE_PRODUCT_IMAGE,
    EDIT_PRODUCT,
    imageUrl,
    PRODUCT_DATA,
} from "../Apis/Api";
import {Editor} from "@tinymce/tinymce-react";

export default function EditProduct()
{

    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [categoriesList, setCategoriesList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [errors , setErrors] = useState({});
    const [productImage, setProductImage] = useState([]);
    const [productSent , setProductSent] = useState(false);
    const [mceEditor , setMceEditor] =  useState(null);
    const navigate = useNavigate();
    const [productData , setProductData] = useState({
        product_name:"",
        product_content:"",
        product_url:"",
        meta_title:"",
        meta_description:"",
        image :"",
        status:0,
        featured:0,
        best_seller:0,
        no_index:0,
        hide_from_nav:0,
        locale:""

    });


    const productNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const fillUrl =  () => {

        let name = productNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setProductData({...productData, ['product_url']: NSlocale + '/' + NSname});
        }else{
            metaUrl.current.value = "";
            setProductData({...productData, ['product_url']: ""});
        }
    }

    const fillMetaTitle = ()=> {
        let name = productNameRef.current.value;
        if(name !=="")
        {
            let siteName = 'androas';
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            setProductData({...productData, ['meta_title']: NSname + ' | ' + siteName});
        }else{
            metaTitle.current.value = "";
            setProductData({...productData, ['meta_title']: ""});
        }
    }

    const [status , setStatusCheck] = useState(productData.status === 1);
    const [featured , setFeaturedCheck] = useState(productData.featured === 1);
    const [bestSeller , setBestSellerCheck] = useState(productData.best_seller === 1);
    const [noIndex , setNoIndexCheck] = useState(productData.no_index === 1);
    const [hideFromNav , setHideFromNavCheck] = useState(productData.hide_from_nav === 1);

    const currentProductImage = useRef(null);
    const productImageRef = useRef();

    const setStatusFunction =(e)=>{
        e.persist();
        setStatusCheck(!status);
    }

    const setFeaturedFunction = (e)=>{
        e.persist();
        setFeaturedCheck(!featured);
    }

    const setBestSellerFunction = (e)=>{
        e.persist();
        setBestSellerCheck(!bestSeller);
    }

    const setNoIndexFunction = (e)=>{
        e.persist();
        setNoIndexCheck(!noIndex);
    }

    const setHideFromNavFunction = (e)=>{
        e.persist();
        setHideFromNavCheck(!hideFromNav);
    }

    const [selectedCategoryValue , setCategorySelectedValue] = useState(0);
    const [selectedBrandValue , setBrandSelectedValue] = useState(0);

    const handleInput = (e) => {
        e.persist();
        setProductData({...productData, [e.target.name]:e.target.value });

    }
    const id = Number(window.location.pathname.replace('/edit-product/',""));

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${CATEGORIES_TABLE}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setCategoriesList(res.data[0])
            });

            await axios.get(`${baseURL}/${BRAND_SELECT_LIST}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setBrandList(res.data[0])
            });

            await axios.get(`${baseURL}/${PRODUCT_DATA}/${id}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setProductData(res.data.productData)
                setMceEditor(res.data.productData.product_content)
                setCategorySelectedValue(res.data.productData.category_id)
                setBrandSelectedValue(res.data.productData.brand_id)
                setStatusCheck(res.data.productData.status)
                setFeaturedCheck(res.data.productData.featured)
                setBestSellerCheck(res.data.productData.best_seller)
                setNoIndexCheck(res.data.productData.no_index)
                setHideFromNavCheck(res.data.productData.hide_from_nav)
            });
        };
        getData();
    }, [productSent]);


    const deleteProductImage=() => {
        axios.post(`${baseURL}/${DELETE_PRODUCT_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            setProductSent(!productSent);
            swal("Success",res.data.message,"success");

        })
    }

    const updateProduct = (e) => {
        e.preventDefault();
        // Send Form Data
        let accept = true;
        setErrors(Validation(productData  , selectedBrandValue , selectedCategoryValue , null));


        if(productData.product_name==="" || productData.product_url==="" || productData.meta_title==="" || selectedCategoryValue ===0 || selectedBrandValue ===0) {

            accept = false;

        }else{
            accept = true;

        }
        if(accept===true)
        {

            // Send Form Data
            const formData = new FormData();
            formData.append('category_id', selectedCategoryValue);
            formData.append('brand_id', selectedBrandValue);
            formData.append('product_name', productData.product_name);
            formData.append('product_content', mceEditor || "");
            formData.append('product_url', productData.product_url);
            formData.append('meta_title', productData.meta_title);
            formData.append('meta_description', productData.meta_description || "");
            formData.append('image',productImage[0]);
            formData.append('current_product_image',currentProductImage.current.value);
            formData.append('featured', featured ? '1':'0');
            formData.append('best_seller', bestSeller ? '1':'0');
            formData.append('status', status ? '1':'0');
            formData.append('no_index', noIndex ? '1':'0');
            formData.append('hide_from_nav', hideFromNav ? '1':'0');

            axios.post(`${baseURL}/${EDIT_PRODUCT}/${id}`,formData,{
                    headers:{
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                },
            ).then(res=>{
                if(res.data.status === 200)
                {
                    swal("Success",res.data.message,"success");
                    navigate('/products-list' , {replace:true});

                }
                else
                {
                    swal("Warning",res.data.error,"warning");
                }
            });
        }
    }

    return (
      <div>
          <Header/>
          <main id="homeMain" tabIndex="-1">
              <div className="container-fluid">
                  <div className="containerWrap">
                      <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={updateProduct}>
                          <div className="row bodyHeader">
                              <div className="col-auto">
                                  <h1>Edit product</h1>
                                  <ul className="breadcrumb">
                                      <li><Link to="/dashboard">Dashboard</Link></li>
                                      <li><Link to="/products-list">Products table</Link></li>
                                      <li><Link to="#">Edit product</Link></li>
                                  </ul>
                              </div>
                          </div>
                          <div className="row">
                              <div className="mainBody formWrap">
                                  <h2>Edit product</h2>
                                  <div className="formGroup required">
                                      <label htmlFor="locale" className="form-label">Language</label>
                                      <input type="text"
                                             ref={localeRef}
                                             id="locale"
                                             name="locale"
                                             readOnly
                                             className="form-control"
                                             value={productData.locale || ""}
                                             onChange={handleInput}
                                      />
                                  </div>
                                  <div className="formGroup required">
                                      <label>Select category</label>
                                      <select
                                          className="form-control"
                                          data-live-search="true" name="category_id" id="category_id"
                                          defaultValue={productData.category_id}
                                          value={selectedCategoryValue}
                                          onChange={(e)=>setCategorySelectedValue(e.target.value)}>
                                          <option value='0'>select category</option>
                                          {categoriesList.map((category,index)=>
                                              <option key={index}   value={category.id}>{category.category_name}</option>
                                          )}
                                      </select>
                                      {errors.category_id && <em className="error hint" style={{color:'red'}}>{errors.category_id}</em>}
                                  </div>
                                  <div className="formGroup required">
                                      <label>Select brand</label>
                                      <select
                                          className="form-control"
                                          value={selectedBrandValue}
                                          defaultValue={productData.brand_id}
                                          data-live-search="true" name="brand_id" id="brand_id"
                                          onChange={(e)=>setBrandSelectedValue(e.target.value)}>
                                          <option value='0'>select brand</option>
                                          {brandList.map((brand,index)=>
                                              <option key={index}   value={brand.id}>{brand.brand_name}</option>
                                          )}
                                      </select>
                                      {errors.brand_id && <em className="error hint" style={{color:'red'}}>{errors.brand_id}</em>}
                                  </div>

                                  <div className="formGroup required">
                                      <label htmlFor="product_name" className="form-label">product name</label>
                                      <input type="text"
                                             ref={productNameRef}
                                             id="product_name"
                                             name="product_name"
                                             className="form-control"
                                             value={productData.product_name || ""}
                                             onChange={handleInput}
                                      />
                                      {errors.product_name && <em className="error hint" style={{color:'red'}}>{errors.product_name}</em>}
                                  </div>

                                  <div className="formGroup quarter required">
                                      <label htmlFor="product_url" className="form-label">Url</label>
                                      <input
                                          ref={metaUrl}
                                          className="form-control"
                                          name="product_url"
                                          id="product_url"
                                          value={productData.product_url || ""}
                                          onChange={handleInput}
                                      />
                                      <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                      {errors.product_url && <em className="error hint" style={{color:'red'}}>{errors.product_url}</em>}

                                  </div>


                                  <div className="formGroup quarter required">
                                      <label htmlFor="meta_title" className="form-label">Meta title</label>
                                      <input
                                          ref={metaTitle}
                                          className="form-control"
                                          name="meta_title"
                                          id="meta_title"
                                          value={productData.meta_title || ""}
                                          onChange={handleInput}
                                      />
                                      <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                      {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}

                                  </div>


                                  <div className="formGroup quarter">
                                      <label htmlFor="meta_description" className="form-label">Meta description</label>
                                      <input
                                          className="form-control"
                                          name="meta_description"
                                          id="meta_description"
                                          value={productData.meta_description || ""}
                                          onChange={handleInput}
                                      />
                                  </div>



                                  <div className="formGroup">
                                      <label htmlFor="image" className="form-label">Image</label>
                                      <input type="file" name="image"
                                             ref={productImageRef}
                                             onChange={(e) =>setProductImage(e.target.files)}
                                             className="form-control"/>
                                      <input type="hidden" name="current_product_image"
                                             ref={currentProductImage}
                                             onChange={(e)=>console.log('')}
                                             value={productData.image ===null  || productData.image ==="" ? "" : productData.image}/>
                                      {productData.image ===null || productData.image ==="" ? "":
                                          <div>
                                              <img  style={{width:'100px'}} src={`${imageUrl}products/${productData.image}`}/>
                                              <a type='button' onClick={deleteProductImage}>delete</a>
                                          </div>
                                      }
                                  </div>

                                  <div className="formGroup quarter">
                                      <label htmlFor="product_content" className="form-label">Product Content</label>
                                      <Editor
                                          apiKey='kds61advbpqoiaoaf8d490hcgwr9ula3jelsk1l0xwgq4ixh'
                                          id="product_content"
                                          value={mceEditor}
                                          onEditorChange={setMceEditor}
                                      />
                                  </div>

                                  <div className="formGroup wide checkGroup">
                                <span>
                                  <input
                                      type="checkbox"
                                      name="status"
                                      onChange={setStatusFunction}
                                      checked={status}
                                      className="form-check-input"

                                  />
                                    <label htmlFor="status" className="form-check-label">Status</label>
                                </span>

                                      <span>
                                     <input
                                         type="checkbox"
                                         name="featured"
                                         onChange={setFeaturedFunction}
                                         className="form-check-input"
                                         checked={featured}

                                     />
                                    <label htmlFor="featured" className="form-check-label">Featured</label>
                                </span>

                                      <span>
                                    <input
                                        type="checkbox"
                                        name="best_seller"
                                        onChange={setBestSellerFunction}
                                        className="form-check-input"
                                        checked={bestSeller}
                                    />
                                    <label htmlFor="best_seller" className="form-check-label">Best seller</label>
                                </span>

                                      <span>
                                    <input
                                        type="checkbox"
                                        name="no_index"
                                        onChange={setNoIndexFunction}
                                        className="form-check-input"
                                        checked={noIndex}

                                    />
                                    <label htmlFor="no_index" className="form-check-label">Sort order</label>
                                </span>

                                      <span>
                                    <input
                                        type="checkbox"
                                        name="hide_from_nav"
                                        onChange={setHideFromNavFunction}
                                        className="form-check-input"
                                        checked={hideFromNav}

                                    />
                                    <label htmlFor="hide_from_nav" className="form-check-label">Hide from nav</label>
                                </span>
                                  </div>
                                  <hr/>
                                  <div className="formGroup wide">
                                      <button type="submit" className="bttn2">save</button>
                                  </div>
                              </div>
                              <div className="functionBar">
                                  <div className="col-auto fanctions">
                                      <Link to="" className="fancItem alert-success">
                                          <i className="bi bi-card-list"></i><span>customers</span>
                                      </Link>
                                      <Link to="" className="fancItem alert-warning">
                                          <i className="bi bi-node-plus"></i><span>add_supplier</span>
                                      </Link>
                                      <Link to="" className="fancItem alert-primary">
                                          <i className="bi bi-node-plus"></i><span>create_account</span>
                                      </Link>
                                  </div>
                                  <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>

          </main>
          <Footer/>
      </div>
    )
}
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import swal from "sweetalert";
import {baseURL, LOGOUT, USER} from "../Apis/Api";


export default function Header(){

    const [logo , setLogo] = useState();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const navigate = useNavigate();
    const [name , setName] = useState('');
    useEffect(()=>{
        axios.get(`${baseURL}/${USER}`,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((data)=>{
            setName(data.data.name);
        })
    },[])

    async function logOut()
    {
        await axios.get(`${baseURL}/${LOGOUT}`,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            if(res.data.status === 200)
            {
                navigate('/backoffice')
            }
        });
    }
    return (
        <header>
            <div className="container-fluid custom-navbar">
                <nav className="navbar navbar-expand-lg">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-content" aria-controls="navbar-content" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Link className="navbar-brand" to=""><img src={require('../styles/images/footer-logo.png')} alt=""/></Link>
                    <div className="collapse navbar-collapse nav-side" id="navbar-content">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Categories</Link>
                                <ul className="dropdown-menu shadow">
                                    <li><Link className="dropdown-item" to="/add-category">New category</Link></li>
                                    <li><Link className="dropdown-item" to="/categories-list">Categories list</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Brands</Link>
                                <ul className="dropdown-menu double shadow">
                                    <li><Link className="dropdown-item" to="/add-brand">New brand</Link></li>
                                    <li><Link className="dropdown-item" to="/brands-list">Brands list</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Products</Link>
                                <ul className="dropdown-menu double shadow">
                                    <li><Link className="dropdown-item" to="/add-product">New product</Link></li>
                                    <li><Link className="dropdown-item" to="/products-list">Products list</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Pages</Link>
                                <ul className="dropdown-menu double shadow">
                                    <li><Link className="dropdown-item" to="/add-page">New page</Link></li>
                                    <li><Link className="dropdown-item" to="/pages-list">Pages list</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Profile</Link>
                                <ul className="dropdown-menu double shadow">
                                    <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown"></li>
                        </ul>
                    </div>
                    <div className="dropdown collapse-line ">
                        <div className="User-name" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                             aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="outside">
                            <span id="name">{name}</span>
                            <span id="user-icon">
                                <i className="bi bi-person-fill-gear"></i>
                            </span>
                        </div>
                        <div className="dropdown-menu collapce-interface" aria-labelledby="dropdownMenuButton">
                            <div className="drop-interface">

                                <a href="" onClick={logOut}  className="user-menu-item">تسجيل خروج</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
)
}
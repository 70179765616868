import {Link, useNavigate, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {
    baseURL,
    CATEGORIES,
    CATEGORY_DATA,
    DELETE_CATEGORY_BANNER_IMAGE,
    DELETE_CATEGORY_BANNER_MOBILE_IMAGE,
    DELETE_CATEGORY_IMAGE, EDIT_CATEGORY, imageUrl
} from "../Apis/Api";

export default function EditCategory()
{

    const {id} = useParams();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [categoryList, setCategoryList] = useState([]);
    const [errors , setErrors] = useState({});
    const [categoryImage, setCategoryImage] = useState([]);
    const [bannerImage, setBannerImage] = useState([]);
    const [mobileBannerImage, setMobileBannerImage] = useState([]);
    const [categorySent , setCategorySent] = useState(false);
    const navigate = useNavigate();
    const [categoryData , setCategoryData] = useState({
        category_name:"",
        category_content:"",
        category_url:"",
        category_image:"",
        category_banner:"",
        category_mobile_banner:"",
        meta_title:"",
        meta_description:"",
        parent_id:"",
        status:0,
        in_footer:0,
        in_home:0,
        sort_order:0,
        locale:""

    });

    const [status , setStatusCheck] = useState(categoryData.status === 1);
    const [inFooter , setInFooterCheck] = useState(categoryData.in_footer === 1);
    const [inHome , setInHomeCheck] = useState(categoryData.in_home === 1);
    const [sortOrder , setSortOrderCheck] = useState(categoryData.sort_order === 1);

    const categoryImageRef = useRef();
    const categoryBannerImageRef = useRef();
    const categoryMobileBannerImageRef = useRef();

    const currentCategoryImage = useRef(null);
    const currentCategoryBanner = useRef(null);
    const currentMobileBannerImage = useRef(null);

    const categoryNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const fillUrl =  () => {

        let name = categoryNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setCategoryData({...categoryData, ['category_url']: NSlocale + '/' + NSname });
        }else{
            metaUrl.current.value = "";
            setCategoryData({...categoryData, ['category_url']:"" });

        }
    }

    const fillMetaTitle = ()=> {
        let name = categoryNameRef.current.value;
        let siteName = 'androas';
        if(name!=="")
        {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            categoryData.meta_title = NSname + ' | ' + siteName;
        }
    }



    const setStatusFunction =(e)=>{
        e.persist();
        setStatusCheck(!status);
}

    const setInHomeFunction = (e)=>{
        e.persist();
        setInHomeCheck(!inHome);
    }

    const setInFooterFunction = (e)=>{
        e.persist();
        setInFooterCheck(!inFooter);
    }

    const setSortOrderFunction = (e)=>{
        e.persist();
        setSortOrderCheck(!sortOrder);
    }

    const [selectedValue , setSelectedValue] = useState();

    const handleInput = (e) => {
        e.persist();
        setCategoryData({...categoryData, [e.target.name]:e.target.value });

    }

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${CATEGORIES}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setCategoryList(res.data[0])
            });

            await axios.get(`${baseURL}/${CATEGORY_DATA}/${id}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setCategoryData(res.data.categoryData)
                setSelectedValue(res.data.categoryData.parent_id)
                setStatusCheck(res.data.categoryData.status)
                setInFooterCheck(res.data.categoryData.in_footer)
                setInHomeCheck(res.data.categoryData.in_home)
                setSortOrderCheck(res.data.categoryData.sort_order)
            });
        };
        getData();
    }, [categorySent]);

    const deleteCategoryImage=() => {
       axios.post(`${baseURL}/${DELETE_CATEGORY_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            setCategorySent(!categorySent);
            swal("Success",res.data.message,"success");

        })
    }


    const deleteCategoryBannerImage=() => {
        axios.post(`${baseURL}/${DELETE_CATEGORY_BANNER_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            setCategorySent(!categorySent);
            swal("Success",res.data.message,"success");

        })
    }

    const deleteBannerMobileImage=() => {
        axios.post(`${baseURL}/${DELETE_CATEGORY_BANNER_MOBILE_IMAGE}/${id}`,null,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            setCategorySent(!categorySent);
            swal("Success",res.data.message,"success");

        })
    }
    const updateCategory = (e) => {
        e.preventDefault();
        // Send Form Data
        let accept = true;
        setErrors(Validation(categoryData));

        if(categoryData.category_name==="" || categoryData.category_url==="" || categoryData.meta_title==="") {

            accept = false;

        }else{
            accept = true;

        }
        if(accept===true)
        {

            // Send Form Data
            const formData = new FormData();
            formData.append('parent_id', selectedValue);
            formData.append('category_name', categoryData.category_name);
            formData.append('category_content', categoryData.category_content || "");
            formData.append('category_url', categoryData.category_url);
            formData.append('meta_title', categoryData.meta_title);
            formData.append('meta_description', categoryData.meta_description || "");
            formData.append('category_image',categoryImage[0]);
            formData.append('current_category_image',currentCategoryImage.current.value);
            formData.append('banner_image',bannerImage[0]);
            formData.append('current_banner_image',currentCategoryBanner.current.value);
            formData.append('mobile_banner_image',mobileBannerImage[0]);
            formData.append('current_mobile_banner_image',currentMobileBannerImage.current.value);
            formData.append('in_home', inHome ? '1':'0');
            formData.append('in_footer', inFooter ? '1':'0');
            formData.append('status', status ? '1':'0');
            formData.append('sort_order', sortOrder ? '1':'0');
            formData.append('locale', categoryData.locale);

            axios.post(`${baseURL}/${EDIT_CATEGORY}/${id}`,formData,{
                    headers:{
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                },
            ).then(res=>{
                if(res.data.status === 200)
                {
                    swal("Success",res.data.message,"success");
                    categoryImageRef.current.value = "";
                    categoryBannerImageRef.current.value = "";
                    categoryMobileBannerImageRef.current.value = "";
                    navigate('/categories-list' , {replace:true});
                }
                else
                {
                    swal("Warning",res.data.error,"warning");
                }
            });
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={updateCategory}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Edit category</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/categories-list">Categories table</Link></li>
                                        <li><Link to="#">Edit category</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Edit category</h2>
                                    <div className="formGroup required">
                                        <label htmlFor="locale" className="form-label">Language</label>
                                        <input type="text"
                                               ref={localeRef}
                                               id="locale"
                                               name="locale"
                                               readOnly
                                               className="form-control"
                                               value={categoryData.locale || ""}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label>Select category</label>
                                        <select
                                            className="form-control"
                                            defaultValue={categoryData.parent_id}
                                            value={selectedValue}
                                            data-live-search="true" name="main_category" id="main_category"
                                            onChange={(e)=>setSelectedValue(e.target.value)}>
                                            <option value='0'>select category</option>
                                            {categoryList.map((category,index)=>
                                                <option key={index}  value={category.id}>{category.category_name}</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="category_name" className="form-label">Category name</label>
                                        <input type="text"
                                               ref={categoryNameRef}
                                               id="category_name"
                                               name="category_name"
                                               className="form-control"
                                               value={categoryData.category_name || ""}
                                               onChange={handleInput}
                                        />
                                        {errors.category_name && <em className="error hint" style={{color:'red'}}>{errors.category_name}</em>}
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="category_content" className="form-label">Category content</label>
                                        <input type="text"
                                               id="category_content"
                                               name="category_content"
                                               className="form-control"
                                               value={categoryData.category_content || ""}
                                               onChange={handleInput}
                                        />

                                    </div>
                                    <div className="formGroup quarter required">
                                        <label htmlFor="category_url" className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="category_url"
                                            id="category_url"
                                            value={categoryData.category_url ||  "" }
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.category_url && <em className="error hint" style={{color:'red'}}>{errors.category_url}</em>}

                                    </div>


                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={categoryData.meta_title || ""}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}

                                    </div>


                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={categoryData.meta_description || ""}
                                            onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="category_image" className="form-label">Image 500 X 500</label>
                                        <input type="file" name="category_image"
                                               ref={categoryImageRef}
                                               onChange={(e) =>setCategoryImage(e.target.files)}
                                               className="form-control"/>
                                        <input type="hidden" name="current_category_image"
                                               ref={currentCategoryImage}
                                               onChange={(e)=>console.log('')}
                                               value={categoryData.category_image ===null  || categoryData.category_image ==="" ? "" : categoryData.category_image}/>
                                        {categoryData.category_image ===null || categoryData.category_image ==="" ? "":
                                          <div>
                                                <img  style={{width:'100px'}} src={`${imageUrl}categories/${categoryData.category_image}`} />
                                                <a type='button' onClick={deleteCategoryImage}>delete</a>
                                            </div>
                                        }
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="category_banner" className="form-label">Banner image 1900 X 500</label>
                                        <input type="file" name="category_banner"
                                               ref={categoryBannerImageRef}
                                               onChange={(e)=>setBannerImage(e.target.files)}
                                               className="form-control"/>
                                        <input type="hidden" name="current_category_banner"
                                               ref={currentCategoryBanner}
                                               value={categoryData.category_banner ===null || categoryData.category_banner ==="" ? "" : categoryData.category_banner}
                                               onChange={(e)=>console.log('')}/>
                                        {categoryData.category_banner === null || categoryData.category_banner === "" ? "" :
                                         <div>
                                             <img  style={{width:'100px'}} src={`${imageUrl}categories/${categoryData.category_banner}`}/>
                                             <a type='button' onClick={deleteCategoryBannerImage}>delete</a>
                                         </div>
                                        }
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="mobile_banner_image" className="form-label">Mobile banner 767 X 300</label>
                                        <input type="file" name="mobile_banner_image"
                                               ref={categoryMobileBannerImageRef}
                                               onChange={(e)=>setMobileBannerImage(e.target.files)}
                                               className="form-control"/>
                                        <input type="hidden"  name="current_mobile_banner_image"
                                               ref={currentMobileBannerImage}
                                               value={categoryData.category_mobile_banner === null  ||  categoryData.category_mobile_banner === "" ? "" : categoryData.category_mobile_banner}
                                               onChange={(e)=>console.log('')}/>
                                        {categoryData.category_mobile_banner ===null || categoryData.category_mobile_banner === "" ? "" :
                                        <div>
                                            <img style={{width:'100px'}} src={`${imageUrl}categories/${categoryData.category_mobile_banner}`}/>
                                            <a type='button' onClick={deleteBannerMobileImage}>delete</a>
                                        </div>
                                        }

                                    </div>

                                    <div className="formGroup wide checkGroup">
                                <span>
                                  <input
                                      type="checkbox"
                                      name="status"
                                      onChange={setStatusFunction}
                                      checked={status}
                                      className="form-check-input"

                                  />
                                    <label htmlFor="status" className="form-check-label">Status</label>
                                </span>

                                        <span>
                                     <input
                                         type="checkbox"
                                         name="in_home"
                                         onChange={setInHomeFunction}
                                         className="form-check-input"
                                         checked={inHome}

                                     />
                                    <label htmlFor="in_home" className="form-check-label">in_home</label>
                                </span>

                                        <span>
                                    <input
                                        type="checkbox"
                                        name="in_footer"
                                        onChange={setInFooterFunction}
                                        className="form-check-input"
                                        checked={inFooter}
                                    />
                                    <label htmlFor="in_footer" className="form-check-label">in_footer</label>
                                </span>

                                        <span>
                                    <input
                                        type="checkbox"
                                        name="sort_order"
                                        onChange={setSortOrderFunction}
                                        className="form-check-input"
                                        checked={sortOrder}

                                    />
                                    <label htmlFor="sort_order" className="form-check-label">Sort order</label>
                                </span>

                                    </div>
                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
import {Link, useNavigate, useParams} from 'react-router-dom'
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Validation from "../Validation/Validation";
import swal from "sweetalert";
import {baseURL, BRAND_TRANSLATION_DATA, EDIT_BRAND_TRANSLATION} from "../Apis/Api";

export default function EditBrandTranslation ()
{
    const { id , brandId} = useParams();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [brandSent , setBrandSent] = useState(false);
    const selectRef = useRef(0);
    const navigate = useNavigate();
    const [translationData , setTranslationData] = useState({
        brand_name:"",
        brand_content:"",
        brand_url:"",
        meta_title:"",
        meta_description:"",
    });


    const brandNameRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const fillUrl =  () => {

        let name = brandNameRef.current.value;
        let locale = selectRef.current.value;
        if(name !=="" && locale!=="0") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setTranslationData({...translationData, ['brand_url']: NSlocale + '/' + NSname});
        }else{
            metaUrl.current.value = "";
            setTranslationData({...translationData, ['brand_url']: "" });
        }
    }


    const fillMetaTitle = ()=> {
        let name = brandNameRef.current.value;
        if(name !=="")
        {
            let siteName = 'androas';
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            setTranslationData({...translationData, ['meta_title']: NSname + '/' + siteName });
        }else{
            metaTitle.current.value = "";
            setTranslationData({...translationData, ['meta_title']: "" });
        }
    }


    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${BRAND_TRANSLATION_DATA}/${id}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setTranslationData(res.data.translationData)
            });
        };
        getData();
    }, []);

    const handleInput = (e) => {
        e.persist();
        setTranslationData({...translationData, [e.target.name]:e.target.value });
    }

    const editBrandTranslation = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(translationData));

        if(translationData.brand_name==="" || translationData.brand_url==="" || translationData.meta_title==="") {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('brand_id', brandId);
            formData.append('brand_name', translationData.brand_name);
            formData.append('brand_content', translationData.brand_content || "");
            formData.append('brand_url', translationData.brand_url);
            formData.append('meta_title', translationData.meta_title);
            formData.append('meta_description', translationData.meta_description || "");

            axios.post(`${baseURL}/${EDIT_BRAND_TRANSLATION}/${id}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                    if(res.data.status === 200) {
                        swal("Success",res.data.message,"success");
                        navigate('/brands-list',{replace:true})
                    }else{
                        swal("Warning",res.data.error,"warning");

                    }

                }
            );
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={editBrandTranslation}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Edit translation</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/brands-list">Brands table</Link></li>
                                        <li><Link to="#">Edit translation</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Edit Translation</h2>

                                    <div className="formGroup required">
                                        <label htmlFor="locale" className="form-label">Translation</label>
                                        <input type="text"
                                               ref={selectRef}
                                               id="locale"
                                               name="locale"
                                               className="form-control"
                                               value={translationData.locale || ""}
                                               readOnly
                                        />
                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="brand_name" className="form-label">Brand name</label>
                                        <input type="text"
                                               ref={brandNameRef}
                                               id="brand_name"
                                               name="brand_name"
                                               className="form-control"
                                               value={translationData.brand_name || ""}
                                               onChange={handleInput}
                                        />
                                        {errors.brand_name && <em className="error hint" style={{color:'red'}}>{errors.brand_name}</em>}
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="brand_content" className="form-label">Brand Content</label>
                                        <input type="text"
                                               id="brand_content"
                                               name="brand_content"
                                               className="form-control"
                                               value={translationData.brand_content || ""}
                                               onChange={handleInput}
                                        />

                                    </div>

                                    <div className="formGroup quarter required">
                                        <label htmlFor="brand_url" className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="brand_url"
                                            id="brand_url"
                                            value={translationData.brand_url || ""}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.brand_url && <em className="error hint" style={{color:'red'}}>{errors.brand_url}</em>}
                                    </div>


                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={translationData.meta_title || ""}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                    </div>

                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={translationData.meta_description || ""}
                                            onChange={handleInput}
                                        />
                                    </div>

                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                                <div className="functionBar">
                                    <div className="col-auto fanctions">
                                        <Link to="" className="fancItem alert-success">
                                            <i className="bi bi-card-list"></i><span>create brand</span>
                                        </Link>
                                        <Link to="/brands-list" className="fancItem alert-warning">
                                            <i className="bi bi-node-plus"></i><span>brands list</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-primary">
                                            <i className="bi bi-node-plus"></i><span>create_account</span>
                                        </Link>
                                    </div>
                                    <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
import {Link, useNavigate, useParams} from 'react-router-dom'
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Validation from "../Validation/Validation";
import swal from "sweetalert";
import {baseURL, CREATE_PAGE_TRANSLATION, UNUSED_PAGE_TRANSLATION} from "../Apis/Api";

export default function AddPageTranslation ()
{
    const {pageId} = useParams();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [translations , setTranslations] = useState([]);
    const [selectedTranslationValue , setSelectedValue] = useState(0);
    const navigate = useNavigate();

    const [pageData , setPageData] = useState({
        title:"",
        heading_title:"",
        content:"",
        url:"",
        meta_title:"",
        meta_description:"",
        locale:""
    });


    const pageNameRef = useRef('');
    const localeRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const fillUrl =  () => {

        let name = pageNameRef.current.value;
        let locale = localeRef.current.value;
        if(name !=="" && locale!=="0") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setPageData({...pageData, ['url']: NSlocale + '/' + NSname});

            pageData.product_url = NSlocale + '/' + NSname;;
        }else{
            metaUrl.current.value = "";
            setPageData({...pageData, ['url']: ""});
        }
    }

    const fillMetaTitle = ()=> {
        let name = pageNameRef.current.value;
        if(name !=="")
        {
            let siteName = 'androas';
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            pageData.meta_title = NSname + ' | ' + siteName;
        }else{
            metaTitle.current.value = "";
            pageData.meta_title = "";
        }
    }


    const handleInput = (e) => {
        e.persist();
        setPageData({...pageData, [e.target.name]:e.target.value });
    }

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${UNUSED_PAGE_TRANSLATION}/${pageId}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setTranslations(res.data[0])
            });
        };
        getData();
    }, []);

    const addPageTranslation = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(pageData , null , null , selectedTranslationValue));

        if(pageData.title==="" || pageData.url==="" || pageData.meta_title==="" || selectedTranslationValue ===0) {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('page_id', pageId);
            formData.append('title', pageData.title);
            formData.append('heading_title', pageData.heading_title || "");
            formData.append('content', pageData.content);
            formData.append('url', pageData.url);
            formData.append('meta_title', pageData.meta_title);
            formData.append('meta_description', pageData.meta_description || "");
            formData.append('locale', selectedTranslationValue);


            axios.post(`${baseURL}/${CREATE_PAGE_TRANSLATION}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                    if(res.data.status === 200) {
                        swal("Success",res.data.message,"success");
                        navigate('/pages-list' , {replace:true});
                    }else
                    {
                        swal("Warning",res.data.error,"warning");

                    }

                }
            );
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addPageTranslation}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Add translation</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/pages-list">Pages table</Link></li>
                                        <li><Link to="#">Add translation</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Add Translation</h2>
                                    <div className="formGroup required">
                                        <label htmlFor="translation" className="form-label">Translation</label>
                                        <select
                                            ref={localeRef}
                                            className="form-control"
                                            value={selectedTranslationValue}
                                            data-live-search="true" name="translation" id="translation"
                                            onChange={(e)=>setSelectedValue(e.target.value)}
                                        >
                                            <option value='0'>select translation</option>
                                            {translations.map((translation,index)=>
                                                <option key={index}  value={translation}>{translation}</option>
                                            )}
                                        </select>
                                        {errors.selectedTranslation && <em className="error hint" style={{color:'red'}}>{errors.selectedTranslation}</em>}
                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="title" className="form-label">Title</label>
                                        <input type="text"
                                               ref={pageNameRef}
                                               id="title"
                                               name="title"
                                               className="form-control"
                                               value={pageData.title}
                                               onChange={handleInput}
                                        />
                                        {errors.title && <em className="error hint" style={{color:'red'}}>{errors.title}</em>}
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="heading_title" className="form-label">Heading title</label>
                                        <input type="text"
                                               id="heading_title"
                                               name="heading_title"
                                               className="form-control"
                                               value={pageData.heading_title}
                                               onChange={handleInput}
                                        />

                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="content" className="form-label">Page content</label>
                                        <input type="text"
                                               id="content"
                                               name="content"
                                               className="form-control"
                                               value={pageData.content}
                                               onChange={handleInput}
                                        />
                                    </div>

                                    <div className="formGroup quarter required">
                                        <label htmlFor="page_url"  className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="url"
                                            id="url"
                                            value={pageData.url}
                                            onChange={handleInput}
                                        />
                                        {errors.url && <em className="error hint" style={{color:'red'}}>{errors.url}</em>}
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                    </div>


                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={pageData.meta_title}
                                            onChange={handleInput}
                                        />
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                    </div>


                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={pageData.meta_description}
                                            onChange={handleInput}
                                        />
                                    </div>


                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                                <div className="functionBar">
                                    <div className="col-auto fanctions">
                                        <Link to="" className="fancItem alert-success">
                                            <i className="bi bi-card-list"></i><span>create product</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-warning">
                                            <i className="bi bi-node-plus"></i><span>products list</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-primary">
                                            <i className="bi bi-node-plus"></i><span>create_account</span>
                                        </Link>
                                    </div>
                                    <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
import {Link, useNavigate, useParams} from 'react-router-dom'
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Validation from "../Validation/Validation";
import swal from "sweetalert";
import {baseURL, CREATE_PRODUCT_TRANSLATION, UNUSED_PRODUCT_TRANSLATION} from "../Apis/Api";

export default function AddProductTranslation ()
{
    const {productId} = useParams();
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [errors , setErrors] = useState({});
    const [translations , setTranslations] = useState([]);
    const [selectedTranslationValue , setSelectedValue] = useState(0);
    const navigate = useNavigate();

    const [productData , setProductData] = useState({
        product_name:"",
        product_content:"",
        product_url:"",
        meta_title:"",
        meta_description:"",
    });

    const productNameRef = useRef('');
    const selectRef = useRef('');
    const metaUrl = useRef('');
    const metaTitle = useRef('');

    const fillUrl =  () => {

        let name = productNameRef.current.value;
        let locale = selectRef.current.value;
        if(name !=="" && locale!=="0") {
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            let NSlocale = locale.replace(/\s+/g, '-').toLowerCase();
            metaUrl.current.value = NSlocale + '/' + NSname;
            setProductData({...productData, ['product_url']: NSlocale + '/' + NSname});
        }else{
            metaUrl.current.value = "";
            setProductData({...productData, ['product_url']: ""});
        }
    }

    const fillMetaTitle = ()=> {
        let name = productNameRef.current.value;
        if(name !=="")
        {
            let siteName = 'androas';
            let NSname = name.replace(/\s+/g, '-').toLowerCase();
            metaTitle.current.value = NSname + ' | ' + siteName;
            setProductData({...productData, ['meta_title']: NSname + ' | ' + siteName});
        }else{
            metaTitle.current.value = "";
            setProductData({...productData, ['meta_title']: ""});
        }
    }

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${UNUSED_PRODUCT_TRANSLATION}/${productId}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setTranslations(res.data[0])
            });
        };
        getData();
    }, []);


    const handleInput = (e) => {
        e.persist();
        setProductData({...productData, [e.target.name]:e.target.value });
    }

    const addProductTranslation = (e) => {
        e.preventDefault();

        // Send Form Data
        let accept = true;
        setErrors(Validation(productData , null , null , selectedTranslationValue));

        if(productData.product_name==="" || productData.product_url==="" || productData.meta_title==="" || selectedTranslationValue===0) {

            accept = false;

        }else{
            accept = true;

        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('product_id', productId);
            formData.append('product_name', productData.product_name);
            formData.append('product_content', productData.product_content || "");
            formData.append('product_url', productData.product_url);
            formData.append('meta_title', productData.meta_title);
            formData.append('meta_description', productData.meta_description || "");
            formData.append('locale', selectedTranslationValue);


            axios.post(`${baseURL}/${CREATE_PRODUCT_TRANSLATION}`,formData,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }}).then(res=>{
                    if(res.data.status === 200) {
                        //Reset Inputs After Submit
                        setProductData({
                            product_name:"",
                            product_content:"",
                            product_url:"",
                            meta_title:"",
                            meta_description:"",
                        });
                        swal("Success",res.data.message,"success");
                        navigate('/products-list' , {replace:true});
                    }else
                    {
                        swal("Warning",res.data.error,"warning");

                    }

                }
            );
        }
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addProductTranslation}>
                            <div className="row bodyHeader">
                                <div className="col-auto">
                                    <h1>Add translation</h1>
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><Link to="/products-list">Products table</Link></li>
                                        <li><Link to="#">Add translation</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mainBody formWrap">
                                    <h2>Add Translation</h2>

                                    <div className="formGroup required">
                                        <label htmlFor="translation" className="form-label">Translation</label>
                                        <select
                                            ref={selectRef}
                                            className="form-control"
                                            value={selectedTranslationValue}
                                            data-live-search="true" name="translation" id="translation"
                                            onChange={(e)=>setSelectedValue(e.target.value)}
                                        >
                                            <option value='0'>select translation</option>
                                            {translations.map((translation,index)=>
                                                <option key={index}  value={translation}>{translation}</option>
                                            )}
                                        </select>
                                        {errors.selectedTranslation && <em className="error hint" style={{color:'red'}}>{errors.selectedTranslation}</em>}

                                    </div>

                                    <div className="formGroup required">
                                        <label htmlFor="product_name" className="form-label">product name</label>
                                        <input type="text"
                                               ref={productNameRef}
                                               id="product_name"
                                               name="product_name"
                                               className="form-control"
                                               value={productData.product_name}
                                               onChange={handleInput}
                                        />
                                        {errors.product_name && <em className="error hint" style={{color:'red'}}>{errors.product_name}</em>}
                                    </div>

                                    <div className="formGroup">
                                        <label htmlFor="product_content" className="form-label">product Content</label>
                                        <input type="text"
                                               id="product_content"
                                               name="product_content"
                                               className="form-control"
                                               value={productData.product_content}
                                               onChange={handleInput}
                                        />

                                    </div>

                                    <div className="formGroup quarter required">
                                        <label htmlFor="product_url" className="form-label">Url</label>
                                        <input
                                            ref={metaUrl}
                                            className="form-control"
                                            name="product_url"
                                            id="product_url"
                                            value={productData.product_url}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillUrl}  type="button">Auto Fill</button>
                                        {errors.product_url && <em className="error hint" style={{color:'red'}}>{errors.product_url}</em>}
                                    </div>


                                    <div className="formGroup quarter required">
                                        <label htmlFor="meta_title" className="form-label">Meta title</label>
                                        <input
                                            ref={metaTitle}
                                            className="form-control"
                                            name="meta_title"
                                            id="meta_title"
                                            value={productData.meta_title}
                                            onChange={handleInput}
                                        />
                                        <button className="bttn2" onClick={fillMetaTitle}  type="button">Auto Fill</button>
                                        {errors.meta_title && <em className="error hint" style={{color:'red'}}>{errors.meta_title}</em>}
                                    </div>

                                    <div className="formGroup quarter">
                                        <label htmlFor="meta_description" className="form-label">Meta description</label>
                                        <input
                                            className="form-control"
                                            name="meta_description"
                                            id="meta_description"
                                            value={productData.meta_description}
                                            onChange={handleInput}
                                        />
                                    </div>

                                    <hr/>
                                    <div className="formGroup wide">
                                        <button type="submit" className="bttn2">save</button>
                                    </div>
                                </div>
                                <div className="functionBar">
                                    <div className="col-auto fanctions">
                                        <Link to="" className="fancItem alert-success">
                                            <i className="bi bi-card-list"></i><span>create product</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-warning">
                                            <i className="bi bi-node-plus"></i><span>products list</span>
                                        </Link>
                                        <Link to="" className="fancItem alert-primary">
                                            <i className="bi bi-node-plus"></i><span>create_account</span>
                                        </Link>
                                    </div>
                                    <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}
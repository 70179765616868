import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import swal from "sweetalert";
import {baseURL, DELETE_PAGE, PAGES} from "../Apis/Api";

export default function PagesList ()
{

    const [pagesList, setPagesList] = useState([]);
    const cookies = new Cookies();
    const token = cookies.get('Bearer');
    const [deletedPage , setDeletedPage] = useState(false);

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${PAGES}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setPagesList(res.data[0])
            });
        };
        getData();
    }, [deletedPage]);

    async function deletePage(id)
    {
        await axios.get(`${baseURL}/${DELETE_PAGE}/${id}`,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success");

            }else{
                swal("Warning",res.data.error,"warning");

            }
            setDeletedPage(!deletedPage);
        });
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <div className="row bodyHeader">
                            <div className="col-auto">
                                <h1>Pages Table</h1>
                                <ul className="breadcrumb">
                                    <li><Link to="/dashboard">Dashboard</Link></li>
                                    <li><Link to="">Pages table</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mainBody">
                                <table className="table table-striped dataTable no-footer" id="samatek-datatable" aria-describedby="samatek-datatable_info"
                                       data-table-max-column="6">
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Title</th>
                                        <th>Heading title</th>
                                        <th>Main page</th>
                                        <th>Languages</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pagesList.map((page,index)=>
                                            <tr key={index}>
                                                <td>{page.id}</td>
                                                <td>{page.title}</td>
                                                <td>{page.heading_title}</td>
                                                <td> {page.pages ? page.pages.title :'main page'}</td>
                                                <td>
                                                    {page.translations.map((translation,index)=>
                                                        <Link to={`/edit-page-translation/${translation.id}/${page.id}`} key={index}>  {translation.locale}   </Link>
                                                    )}
                                                </td>
                                                <td>{page.status}</td>
                                                <td>
                                      <span className="function">
                                       <Link className="alert-success" title="edit"  to={`/edit-page/${page.id}`}>
                                       <i className="bi bi-pencil-square"></i>
                                        </Link>
                                 <Link className="alert-success" title="add"  to={`/add-page-translation/${page.id}`}>
                                <i className="bi bi-node-plus"></i>
                                </Link>
                                <button onClick={()=>deletePage(page.id)} className="alert-danger" type="submit" title="delete"><i className="bi bi-trash3"></i></button>
                                      </span>
                                                </td>
                                            </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="functionBar">
                                <div className="col-auto fanctions">
                                    <Link to="/add-page" className="fancItem alert-success" title="add">
                                        <i className="bi bi-node-plus"></i><span>New page</span>
                                    </Link>
                                </div>
                                <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
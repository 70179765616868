import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import swal from "sweetalert";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Cookies from "universal-cookie";
import {baseURL, BRANDS, DELETE_BRAND} from "../Apis/Api";

export default function BrandsList ()
{

    const [brandList, setBrandList] = useState([]);
    const [deletedBrand , setDeletedBrand] = useState(false);
    const cookies = new Cookies();
    const token = cookies.get('Bearer');

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${baseURL}/${BRANDS}`,{
                headers:{
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                setBrandList(res.data[0])
            });
        };
        getData();
    }, [deletedBrand]);

    async function deleteBrand(id)
    {
        await axios.get(`${baseURL}/${DELETE_BRAND}/${id}`,{
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            if(res.data.status === 200)
            {
                swal("Success",res.data.message,"success");

            }else{
                swal("Warning",res.data.error,"warning");

            }
            setDeletedBrand(!deletedBrand);
        });
    }

    return (
        <div>
            <Header/>
            <main id="homeMain" tabIndex="-1">
                <div className="container-fluid">
                    <div className="containerWrap">
                        <div className="row bodyHeader">
                            <div className="col-auto">
                                <h1>Brands Table</h1>
                                <ul className="breadcrumb">
                                    <li><Link to="/dashboard">Dashboard</Link></li>
                                    <li><Link to="">Brands table</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mainBody">
                                <table className="table table-striped dataTable no-footer" id="samatek-datatable" aria-describedby="samatek-datatable_info"
                                       data-table-max-column="6">
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Brand name</th>
                                        <th>Meta title</th>
                                        <th>Brand url</th>
                                        <th>Languages</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {brandList.map((brand,index)=>
                                            <tr key={index}>
                                                <td>{brand.id}</td>
                                                <td>{brand.brand_name}</td>
                                                <td>{brand.meta_title}</td>
                                                <td>{brand.brand_url}</td>
                                                <td>
                                                    {brand.translations.map((translation,index)=>
                                                        <Link to={`/edit-brand-translation/${translation.id}/${brand.id}`} key={index}>   {translation.locale} </Link>
                                                    )}
                                                </td>
                                                <td>{brand.status}</td>
                                                <td>
                                <span className="function">
                                <Link className="alert-success" title="edit"  to={`/edit-brand/${brand.id}`}>
                                <i className="bi bi-pencil-square"></i>
                                </Link>
                                      <Link className="alert-success" title="add"  to={`/add-brand-translation/${brand.id}`}>
                                <i className="bi bi-node-plus"></i>
                                </Link>
                                <button onClick={()=>deleteBrand(brand.id)} className="alert-danger" type="submit" title="delete"><i className="bi bi-trash3"></i></button>
                                </span>
                                                </td>
                                            </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="functionBar">
                                <div className="col-auto fanctions">
                                    <Link to="/add-brand" className="fancItem alert-success" title="add">
                                        <i className="bi bi-node-plus"></i><span>New brand</span>
                                    </Link>
                                    {/*<Link to="/products-list" className="fancItem alert-secondary">*/}
                                    {/*    <i className="bi bi-list-check"></i><span>Products table</span>*/}
                                    {/*</Link>*/}
                                </div>
                                <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
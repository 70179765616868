import {Link} from 'react-router-dom'
import {useState} from "react";
import axios from "axios";
import swal from 'sweetalert';
import Validation from "../Validation/Validation";
import {ADD_USER, baseURL} from "../Apis/Api";
import Cookies from "universal-cookie";

export default function AddUser()
{

    const cookies = new Cookies();
    const token = cookies.get('Bearer');

    const [errors , setErrors] = useState({});
    const [userData , setUserData] = useState({
     user_name:"",
     email:"",
     password:"",
     confirm_password:""
    });

    const handleInput = (e) => {
        e.persist();
        setUserData({...userData, [e.target.name]:e.target.value });
    }
    async function addUser (e) {
        e.preventDefault();
        // Send Form Data
        let accept = true;
        setErrors(Validation(userData));

        if(userData.name==="" || userData.email==="" || userData.password==="") {

            accept = false;

        }else{
            accept = true;
        }

        if(accept===true)
        {
            const formData = new FormData();
            formData.append('name', userData.user_name);
            formData.append('email', userData.email);
            formData.append('password', userData.password);
            await  axios.post(`${baseURL}/${ADD_USER}`,formData,{
                    headers:{
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            ).then(res=>{
                if(res.data.status === 200)
                {
                    swal("Success",res.data.message,"success");

                    //Reset Data After Submit
                    setUserData({
                        user_name:"",
                        email:"",
                        password:"",
                    });
                }
            });
        }
    }

    return (
        <main id="homeMain" tabIndex="-1">
            <div className="container-fluid">
                <div className="containerWrap">
                    <form encType="multipart/form-data" noValidate="novalidate" className="formStyle" onSubmit={addUser}>
                        <div className="row bodyHeader">
                            <div className="col-auto">
                                <h1>New User</h1>
                                <ul className="breadcrumb">
                                    <li><Link to="/dashboard">Dashboard</Link></li>
                                    <li><Link to="/users-list">Users table</Link></li>
                                    <li><Link to="#">New User</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mainBody formWrap">
                                <h2>Add User</h2>
                                <div className="formGroup required">
                                    <label htmlFor="user_name" className="form-label">User name</label>
                                    <input type="text"
                                           id="user_name"
                                           name="user_name"
                                           className="form-control"
                                           value={userData.user_name}
                                           onChange={handleInput}
                                    />
                                    {errors.user_name && <em className="error hint" style={{color:'red'}}>{errors.user_name}</em>}
                                </div>

                                <div className="formGroup">
                                    <label htmlFor="email" className="form-label">E-mail</label>
                                    <input type="text"
                                           id="email"
                                           name="email"
                                           required
                                           className="form-control"
                                           value={userData.email}
                                           onChange={handleInput}
                                    />
                                    {errors.email && <em className="error hint" style={{color:'red'}}>{errors.email}</em>}
                                </div>

                                <div className="formGroup">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="text"
                                           id="password"
                                           name="password"
                                           required
                                           className="form-control"
                                           value={userData.password}
                                           onChange={handleInput}
                                    />
                                    {errors.password && <em className="error hint" style={{color:'red'}}>{errors.password}</em>}
                                </div>

                                <div className="formGroup">
                                    <label htmlFor="confirm_password" className="form-label">Confirm password</label>
                                    <input type="text"
                                           id="confirm_password"
                                           name="confirm_password"
                                           required
                                           className="form-control"
                                           value={userData.confirm_password}
                                           onChange={handleInput}
                                    />
                                    {errors.confirm_password && <em className="error hint" style={{color:'red'}}>{errors.confirm_password}</em>}
                                </div>

                                <hr/>
                                <div className="formGroup wide">
                                    <button type="submit" className="bttn2">save</button>
                                </div>
                            </div>
                            <div className="functionBar">
                                <div className="col-auto fanctions">
                                    <Link to="/users-list" className="fancItem alert-success">
                                        <i className="bi bi-card-list"></i><span>Users Table</span>
                                    </Link>
                                </div>
                                <Link className="toTop" to="#"><i className="bi bi-arrow-up-short"></i></Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </main>
    )
}